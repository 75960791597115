<template>
  <span class="currency">
    <vue-numeric currency="$" separator="." :read-only="true" :value="value"></vue-numeric>
  </span>
</template>
<script>
  import VueNumeric from 'vue-numeric';

  export default {
    components: {
      VueNumeric,
    },
    props: ['value'],
  }
</script>
