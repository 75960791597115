import moment from 'moment';

export default {
  serializeQuery(params, prefix) {
    const query = Object.keys(params).map((key) => {
      const value = params[key];
      let newKey = key;

      if (params.constructor === Array) {
        newKey = `${prefix}[]`;
      } else if (params.constructor === Object) {
        newKey = (prefix ? `${prefix}[${newKey}]` : newKey);
      }

      if (typeof value === 'object') {
        return this.serializeQuery(value, newKey);
      }

      return `${newKey}=${encodeURIComponent(value)}`;
    });

    return query.join('&');
  },

  stepSizeForRange(range) {
    const smallRange = 6;
    const smallStep = 0.1;
    let step = 1;

    if (range < smallRange) {
      step = smallStep;
    }

    return step;
  },

  isIntervalStepOrBorder(_min, _max, _interval, _value) {
    const ratio = 100;
    const radix = 10;
    const interval = parseInt(_interval * ratio, radix);
    const value = parseInt(_value * ratio, radix);
    const min = parseInt(_min * ratio, radix);
    const max = parseInt(_max * ratio, radix);

    if (value === min || value === max || value % interval === 0) {
      return true;
    }

    return false;
  },

  snakeToCamel(s) {
    return s.replace(/(_\w)/g, m => m[1].toUpperCase());
  },
  parseDate(_date) {
    const initDateStr = 0;
    const endDateStr = 5;
    const next = 1;
    const today = moment();
    const dateStr = `${_date.substr(initDateStr, endDateStr)}/${today.format('YYYY')}`;
    const date = moment(dateStr, 'DD/MM/YYYY');
    if (date < today) {
      date.add(next, 'year');
    }

    return date;
  },

  translate(word) {
    const wordList = {
      // Packaging
      'bulk': 'Granel',
      'bagged25': 'Ensacado 25 kgs',

      // Form
      'powder': 'Harinado',
      'multiparticle': 'Multipartícula',
      'ground': 'Molido',
      'pellet8': 'Peletizado en 8mm',
      'pellet6': 'Peletizado en 6mm',
      'pellet3': 'Peletizado en 3mm',
      'whole': 'Entero',
    };

    return wordList[word];
  },

  translateList(elements) {
    return elements.map(word => ({ value: word, label: this.translate(word) }));
  },

  keys(possibleObject) {
    let keys = [];
    if (possibleObject) {
      keys = Object.keys(possibleObject);
    }

    return keys;
  },
};
