<template>
  <div class="order-new__resume-content">
    <div class="order-new__head">
      <span>Antes de confirmar la orden, revisa que los datos a continuación esten correctos</span>
    </div>
    <div class="l-order-new__row">
      <div class="order-new__section-user-info">
        <user-info :email="email" />
        <h2 class="order-new__subtitle">
          Ubicación de entrega
        </h2>
        <address-details />
        <h2 class="order-new__subtitle">
          Fecha estimada de entrega
        </h2>
        <table class="g-table">
          <tr>
            <td class="g-table__biggest-cell">
              D&iacute;a
            </td>
            <td>{{ formattedDesiredDate }}</td>
          </tr>
        </table>
        <h3 class="order-new__subtitle">
          Información general
        </h3>
        <div>
          <table class="g-table">
            <tr>
              <td class="g-table__biggest-cell">
                Nombre de fórmula
              </td>
              <td>{{ formulaName }}</td>
            </tr>
            <tr>
              <td class="g-table__biggest-cell">
                Tipo de venta
              </td>
              <td>
                <span>
                  <span>{{ this.saleType }}</span>
                </span>
              </td>
            </tr>
            <tr>
              <td>Envase</td>
              <td>
                <span v-if="packaging">{{ packaging.shortLabel || packaging.label }}</span>
              </td>
            </tr>
            <tr>
              <td>Formato</td>
              <td>
                <span v-if="form">{{ form.label }}</span>
              </td>
            </tr>
            <tr>
              <td>Peso</td>
              <td>
                <vue-numeric
                  separator="."
                  :read-only="true"
                  :value="weight"
                /> kg
              </td>
            </tr>
          </table>
        </div>
        <h3 class="order-new__subtitle">
          Información del contenido
        </h3>
        <formula-details />
      </div>
    </div>
    <div class="order-new__payments-info">
      <div class="text-center order-new__payments">
        <h2 class="order-new__subtitle order-new__subtitle--payments">
          Modo de Pago
        </h2>
        <span class="order-new__payment">
          <input
            id="online"
            type="radio"
            name="wayToPay"
            v-model="wayToPay"
            value="online"
          >
          <label for="online">Tarjeta de Cr&eacute;dito</label>
          <p class="order-new__payment-description">Se sumará un {{ onlinePayCommission * 100 }}% al servicio de venta</p>
        </span>
        <span class="order-new__payment">
          <input
            id="deposit"
            type="radio"
            name="wayToPay"
            v-model="wayToPay"
            value="deposit"
          >
          <label for="deposit">Al contado</label>
          <p class="order-new__payment-description">Se descontará un {{ depositPayCommission * 100 }}% al servicio de venta</p>
        </span>
        <span
          class="order-new__payment"
          v-if="creditAllowed"
        >
          <input
            id="wired"
            type="radio"
            name="wayToPay"
            v-model="wayToPay"
            value="wired"
          >
          <label for="wired">Cr&eacute;dito Alisur</label>
        </span>
      </div>
      <div>
        <h2 class="order-new__subtitle">
          Valor a pagar
        </h2>
        <amount-details />
      </div>
    </div>
    <div>
      <div class="order-new__section-details__button">
        <span
          class="btn btn-outline"
          @click="back();"
        >
          Volver
        </span>
        <span
          class="btn"
          :class="{ enabled: wayToPay && !loading }"
          @click="createOrder();"
          :disabled="!wayToPay || loading"
        >
          Finalizar compra
        </span>
      </div>
    </div>
    <div>
      <h2 class="text-center order-new__subtitle">
        Condiciones de compra
      </h2>
      <ul class="order-new__change-policies">
        <li>Puedes realizar cambios a tu producto, hasta seis días hábiles antes de la fecha programada de entrega. El precio resultante será el vigente al momento del cambio. No se hacen devoluciones de dinero</li>
        <li>Puedes realizar cambios a la fecha de entrega, hasta dos días hábiles antes de la fecha programada. Los cambios quedan sujetos a la disponibilidad productiva. No se admitirán cambios pasado esa fecha.</li>
        <li>A más tardar el {{ formattedNotifyDate }} te enviaremos un correo confirmando la fecha programada de entrega</li>
        <li>Los pagos realizados fuera de plazo generan intereses</li>
      </ul>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import VueNumeric from 'vue-numeric';
import store from '../store';
import userInfo from './user-info.vue';
import formulaDetails from './formula-details.vue';
import amountDetails from './amount-details.vue';
import addressDetails from './address-details.vue';

export default {
  store,
  components: {
    VueNumeric,
    userInfo,
    formulaDetails,
    amountDetails,
    addressDetails,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.$store.dispatch('updateWayToPay', '');
  },
  computed: {
    desiredDate() {
      return this.$store.state.order.desiredDate;
    },
    formattedDesiredDate() {
      return moment(this.desiredDate).format('DD/MM/YYYY');
    },
    formattedNotifyDate() {
      const daysBeforeDesiredDate = 4;

      return moment(this.desiredDate).subtract(daysBeforeDesiredDate, 'd').format('DD/MM');
    },
    productCategory() {
      return this.$store.state.recipe.details.category;
    },
    recipeType() {
      return this.$store.state.recipe.type;
    },
    via() {
      return this.$store.state.via;
    },
    isProduct() {
      return this.recipeType === 'product';
    },
    weight() {
      return this.$store.state.recipe.weight;
    },
    saleType() {
      if (this.isProduct) return this.$t('order.productCategory')[this.productCategory];

      return this.$t('order.via')[this.via];
    },
    formulaName() {
      return this.$store.state.order.formulaName || this.saleType;
    },
    wayToPay: {
      get() {
        return this.$store.state.order.wayToPay;
      },
      set(value) {
        this.$store.dispatch('updateWayToPay', value);
      },
    },
    packaging() {
      return this.$store.state.recipe.finishing.packaging;
    },
    form() {
      return this.$store.state.recipe.finishing.form;
    },
    creditAllowed() {
      return this.$store.state.order.user.creditAllowed;
    },
    onlinePayCommission() {
      return this.$store.state.onlinePayCommission;
    },
    depositPayCommission() {
      return this.$store.state.depositPayCommission;
    },
  },
  methods: {
    createOrder() {
      if (this.wayToPay && !this.loading) {
        this.$store.dispatch('createOrder');
        this.loading = true;
      }
    },
    back() {
      this.$emit('back');
    },
  },
};
</script>
