import helpers from '../../helpers';
import api from '../api';

export default {
  state: {
    price: 0,
    weight: 0,
    nutritionalInfo: {
      'pc': 0,
      'em': 0,
      'cne': 0,
      'ee': 0,
      'fdn': 0,
      'ct': 0,
      'fc': 0,
      'ms': 0,
    },
    ingredientsProportion: {},
    type: '',
    details: null,
    finishing: {
      form: { value: 'powder', label: helpers.translate('powder') },
      packaging: { value: 'bulk', label: helpers.translate('bulk') },
      totalSackWeight: null,
    },
    dailyWeightRequirement: null,
    updated: false,
  },
  mutations: {
    updateRecipe: (state, payload) => {
      state.price = payload.price;
      state.weight = payload.weight;
      state.nutritionalInfo = payload.nutritional_info;
      state.ingredientsProportion = Object.assign({}, ...payload.ingredients_proportion);
    },
    updateProductInformation: (state, payload) => {
      const ton = 1000;
      state.price = payload.kilogram_price;
      state.weight = payload.unit_tons * ton;
      state.type = 'product';
      state.details = {};
      state.details.name = payload.name;
      state.details.description = payload.description;
      state.details.id = payload.id;
      state.details.category = payload.category;
      payload.product_ingredients.forEach(ingredient => {
        state.ingredientsProportion[ingredient.name] = ingredient.percentage;
      });
    },
    updateProductIngredient: (state, payload) => {
      state.nutritionalInfo.pc = payload.pc;
      state.nutritionalInfo.em = payload.em;
      state.nutritionalInfo.cne = payload.cne;
      state.nutritionalInfo.ee = payload.ee;
      state.nutritionalInfo.fdn = payload.fdn;
      state.nutritionalInfo.ct = payload.ct;
      state.nutritionalInfo.fc = payload.fc;
      state.nutritionalInfo.ms = payload.ms;
    },
    updateForm: (state, payload) => { state.finishing.form = payload; },
    updatePackaging: (state, payload) => { state.finishing.packaging = payload; },
    updateTotalSackWeight: (state, payload) => { state.finishing.totalSackWeight = payload; },
    setUpdated: (state, payload) => { state.updated = payload; },
    updateType: (state, payload) => { state.type = payload; },
    updateDailyWeightRequirement: (state, payload) => { state.dailyWeightRequirement = payload; },
  },
  actions: {
    cheapestRecipe: (context, payload) => {
      api.cheapestRecipe(payload)
        .then((recipe) => context.dispatch('saveFormulaRecipe', recipe))
        .catch((e) => console.log(e));
    },
    inverseRecipe: (context, { ingredients, nutritionalInfo = null }) => {
      api.inverseRecipe(ingredients, context.state.finishing.form.value, context.state.finishing.packaging.value,
        context.state.finishing.totalSackWeight, nutritionalInfo)
        .then((recipe) => context.dispatch('saveFormulaRecipe', recipe))
        .catch((e) => console.log(e));
    },
    updateDailyWeightRequirement: (context, payload) => {
      context.commit('updateDailyWeightRequirement', payload);
    },
    saveFormulaRecipe: (context, recipe) => {
      context.commit('updateType', 'formula');
      context.commit('updateRecipe', recipe);
      context.commit('setUpdated', true);
    },
  },
};
