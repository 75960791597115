<template>
  <div>
    <div
      class="formulator__sliders formulator__sliders--extension"
      v-if="show"
    >
      <formulator-slider
        v-for="ingredient in ingredients"
        :key="ingredient.name"
        :name="ingredient.name"
        unit="%"
        :min="ingredient.min"
        :max="ingredient.max"
        :interval="ingredient.interval"
        :value="ingredientRanges[ingredient.id]"
        @input="(val) => updateRangeValue(ingredient.id, val)"
      />
    </div>
    <hr
      class="sliders-separator"
      v-if="show"
    >
    <div class="formulator__extension-btn">
      <button
        class="btn btn-none btn--h-center"
        @click="updateShow"
      >
        <div
          class="dropdown-icon"
          :class="{ 'dropdown-icon--up': show }"
        />
      </button>
    </div>
  </div>
</template>
<script>
import formulatorSlider from './formulator-slider.vue';

export default {
  components: {
    formulatorSlider,
  },
  computed: {
    ingredients() {
      return this.$store.getters.generalIngredients;
    },
    ingredientRanges() {
      return this.$store.state.formulator.nutritionFormulator.ingredientRanges;
    },
    show() {
      return this.$store.state.formulator.nutritionFormulator.showExtension;
    },
  },
  methods: {
    updateShow() {
      this.$emit('update-show', !this.show);
    },
    updateRangeValue(id, value) {
      this.$emit('update-range-value', { id, value });
    },
  },
};
</script>
