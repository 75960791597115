<template>
  <div :class="['sign-in-overlay', {'sign-in-overlay--inactive': !showOverlay}]"></div>
</template>

<script>
export default {
  computed: {
    showOverlay() {
      return this.$store.state.showOverlay;
    },
  },
};
</script>
