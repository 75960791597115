export default {
  user: {
    userTitle: 'Información de Contacto',
    businessTitle: 'Detalles de Facturación',
    securityTitle: 'Seguridad',
    attributes: {
      name: 'Nombre',
      surname: 'Apellido',
      email: 'Correo',
      number1: 'Número 1',
      number2: 'Número 2',
      billingAttributes: {
        rut: 'RUT empresa',
        name: 'Nombre empresa',
        businessCategory: 'Giro',
        businessAddress: 'Dirección de empresa',
      },
    },
    security: {
      currentPassword: 'Clave Actual',
      password: 'Clave Nueva',
      passwordConfirmation: 'Confirmación de Clave',
    },
  },
  order: {
    via: {
      recipe: 'Restricción nutrientes',
      ingredient: 'Restricción ingredientes',
      admin: 'Venta personalizada',
    },
    productCategory: {
      raw: 'Materia Prima',
      promo: 'Promociones',
      custom: 'Custom',
    },
  },
};
