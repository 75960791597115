<template>
  <div id="formulator-dairy-production">
    <info-modal
      name="dairy-production-error-modal"
      title="No es posible formular este alimento"
      width="40%"
    >
      <p
        v-for="(message, index) in errorMessages"
        :key="index"
      >
        {{ message }}
      </p>
    </info-modal>
    <div class="formulator__sliders">
      <formulator-slider
        name="Leche"
        unit="l/día"
        min="15"
        max="35"
        interval="1"
        info-modal="high-formulator-modal"
        v-model="milk" />
      <formulator-slider
        name="Peso vivo"
        unit="kg"
        min="400"
        max="650"
        interval="50"
        v-model="weight" />
      <formulator-slider
        name="Proteína"
        unit="%"
        min="3.3"
        max="4"
        interval="0.1"
        v-model="protein" />
      <formulator-slider
        name="Materia grasa"
        unit="%"
        min="3.5"
        max="4.8"
        interval="0.1"
        v-model="fat" />
    </div>
    <hr class="sliders-separator">
    <formulator-slide-and-select
      name="Materia seca"
      unit="kg/día"
      min="11"
      max="27"
      interval="0.1"
      v-model="dryMatter"
      :items="dryMatterOptions"
      :disable-slider="disabledDryMatter"
      :show-custom-amount="!!receivedDryMatter"
      :custom-amount="receivedDryMatter"
    >
      <template
        v-if="!!receivedDryMatter && dryMatter.id !== 'customDmi'"
        v-slot:title
      >
        Materia seca <b>{{ receivedDryMatter }} kg/día</b>
      </template>
    </formulator-slide-and-select>
    <hr class="sliders-separator">
    <formulator-slide-and-select
      name="Pradera disponible"
      unit="kg MS/día"
      min="0"
      max="14"
      interval="1"
      v-model="meadow"
      info-modal="meadow-modal"
      :items="meadows"
      is-ingredient
    />
    <hr class="sliders-separator">
    <formulator-slide-and-select
      v-for="(forage, index) in forageValues"
      :key="`forage-${index}`"
      name="Forraje disponible"
      unit="kg/día"
      min="0"
      max="40"
      interval="1"
      :items="forages"
      v-model="forageValues[index]"
      :info-modal="index === 0 ? 'forage-modal' : ''"
      :initial="index"
      :update-on-select="false"
      is-ingredient
    />
    <hr class="sliders-separator">
    <div class="formulator__sliders">
      <formulator-slider
        v-for="ingredient in ingredients"
        :key="ingredient.name"
        :name="ingredient.name"
        :min="ingredient.min"
        :max="ingredient.max"
        :interval="ingredient.interval"
        v-model="ingredient.value"
        unit="%"
        with-input
        is-ingredient
      />
    </div>
    <hr class="sliders-separator">
    <formulator-salt-selection
      v-model="salt"
    />
    <hr class="sliders-separator">
    <formulator-finishing class="formulator__sliders formulator-finishing" />
    <hr class="sliders-separator">
    <div class="formulator__finish-box">
      <div class="input__button">
        <div
          id="dairy-formulator-btn"
          class="formulator__button"
          :disabled="total != 100"
          @click="requestCncpsExecution()"
        >
          <div v-if="!visibleLoading">
            Calcular
          </div>
          <span
            v-if="visibleLoading"
            class="formulator__button-loader"
          />
        </div>
        <div class="ingredients-warning">
          Total: <b><span :class="{ 'ingredients-warning__error': total != 100 }">{{ total }}</span> %</b>
        </div>
      </div>
    </div>
    <div
      class="formulator__info-message formulator__info-message--error"
      v-if="!!extractionState"
    >
      Ocurrió un error. Por favor, intenta nuevamente luego de algunos minutos.
    </div>
  </div>
</template>
<script>
import formulatorSlider from './formulator-slider.vue';
import formulatorFinishing from './formulator-finishing.vue';
import FormulatorSaltSelection from './formulator-salt-selection';
import FormulatorSlideAndSelect from './formulator-slide-and-select.vue';
import InfoModal from '../modals/info-modal';
import api from '../store/api';

export default {
  components: {
    formulatorSlider,
    formulatorFinishing,
    FormulatorSaltSelection,
    FormulatorSlideAndSelect,
    InfoModal,
  },
  data() {
    return {
      salt: { amount: 0, id: null },
      meadow: { amount: 0, id: null, ms: 0 },
      dryMatter: { amount: 0, id: null },
      dryMatterOptions: [
        { value: 'maxDmi', label: 'Requerimiento máximo', defaultPercentage: '100', disabled: true },
        { value: 'minDmi', label: 'Requerimiento mínimo', defaultPercentage: '0', disabled: true },
        { value: 'customDmi', label: 'Requerimiento personalizado', defaultPercentage: '56.25', disabled: false },
      ],
      forageValues: [{ amount: 0, id: null, ms: 0 }, { amount: 0, id: null }, { amount: 0, id: null, ms: 0 }],
      errorMessages: [],
      extractionState: null,
      receivedDryMatter: 0,
      visibleLoading: false,
    };
  },
  computed: {
    milk: {
      get() {
        return this.$store.state.formulator.dairyInfo.milk;
      },
      set(val) {
        this.$store.commit('updateDairyInfo', { key: 'milk', value: val });
      },
    },
    weight: {
      get() {
        return this.$store.state.formulator.dairyInfo.weight;
      },
      set(val) {
        this.$store.commit('updateDairyInfo', { key: 'weight', value: val });
      },
    },
    protein: {
      get() {
        return this.$store.state.formulator.dairyInfo.protein;
      },
      set(val) {
        this.$store.commit('updateDairyInfo', { key: 'protein', value: val });
      },
    },
    fat: {
      get() {
        return this.$store.state.formulator.dairyInfo.fat;
      },
      set(val) {
        this.$store.commit('updateDairyInfo', { key: 'fat', value: val });
      },
    },
    meadows() {
      return this.$store.getters.ingredientsForSelect('meadow');
    },
    forages() {
      return this.$store.getters.ingredientsForSelect('forage');
    },
    packaging() {
      return this.$store.state.recipe.finishing.packaging.value;
    },
    form() {
      return this.$store.state.recipe.finishing.form.value;
    },
    totalSackWeight() {
      return this.$store.state.recipe.finishing.totalSackWeight;
    },
    ready() {
      return this.salt.id && this.meadow.id && this.forageValues.every(forage => !!forage.id);
    },
    disabledDryMatter() {
      return this.dryMatter.id ? this.dryMatterOptions.find(option => option.value === this.dryMatter.id).disabled : true;
    },
    ingredients() {
      return this.$store.getters.generalIngredients;
    },
    ingredientValues() {
      return this.ingredients.map((ingredient) => ({ amount: ingredient.value, id: ingredient.id, ms: ingredient.ms }));
    },
    total() {
      const ingredientSum = this.ingredients.reduce((total, ingredient) =>
        total + parseFloat(ingredient.value), 0);

      return (ingredientSum + parseFloat(this.salt.amount)).toFixed(1);
    },
  },
  methods: {
    getRecipe() {
      if (Number(this.total) !== 100) return; // eslint-disable-line no-magic-numbers

      const ingredients = this.$store.getters.queryIngredients([this.salt]);
      this.$store.dispatch('inverseRecipe', { ingredients });
      this.$store.commit('updateVia', 'ingredient');
    },
    requestCncpsExecution() {
      const params = {
        milk: this.milk,
        sbw: this.weight,
        protein: this.protein,
        fat: this.fat,
        meadow: [this.meadow],
        forages: this.forageValues,
        ingredients: this.ingredientValues,
        dryMatter: [this.dryMatter],
        salt: this.salt.amount,
      };
      this.receivedDryMatter = 0;
      this.visibleLoading = true;
      this.extractionState = null;
      api.requestCncpsExecution(params)
        .then((response) => {
          const isRequestFulfilled = response[0];
          const data = response[1];

          if (isRequestFulfilled) {
            this.handleRequestCncpsExecutionSuccess(data);
          } else {
            this.handleRequestCncpsExecutionError(data);
          }
        });
    },
    handleRequestCncpsExecutionSuccess(data) {
      this.getCncpsExecution(data.identifier);
    },
    handleRequestCncpsExecutionError(data) {
      console.error(data);
      this.extractionState = 'extraction-error';
      this.visibleLoading = false;
    },
    getCncpsExecution(identifier) {
      const timeToCheckExecution = 5000;
      setTimeout(() => {
        api.getCncpsExecution(identifier).then((response) => {
          console.info(response);
          switch (response.extractionState) {
          case 'completed':
            this.handleCompletedExtractionState(response);
            break;
          case 'failed':
            this.handleRequestCncpsExecutionError(response);
            break;
          default:
            this.getCncpsExecution(response.identifier);
          }
        });
      }, timeToCheckExecution);
    },
    handleCompletedExtractionState(response) {
      this.getRecipe();

      if (this.dryMatter.id !== 'customDmi') {
        this.receivedDryMatter = response.extractionResult.goals.givenDryMatter;
      }
      this.$store.dispatch('updateHighPrecisionGoals', response.extractionResult.goals);
      this.$store.dispatch('updateShowGoals', true);
      this.$store.dispatch('updateDailyWeightRequirement', response.extractionResult.dailyWeightRequirement);
      this.visibleLoading = false;
    },
    resetReceivedDryMatter() {
      this.receivedDryMatter = 0;
    },
  },
  watch: {
    ready(val) {
      if (val && !this.$store.state.firstRecipeLoaded) {
        this.getRecipe();
        this.$store.commit('updateFirstRecipeLoaded', true);
      }
    },
    dryMatter: {
      deep: true,
      handler: 'resetReceivedDryMatter',
    },
  },
};
</script>
