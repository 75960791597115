<template>
  <div>
    <modal-product />
    <info-modal
      name="high-formulator-modal"
      title="Formulador de Alta Precisión"
    >
      <div>
        <p>Este formulador está en proceso de pruebas por lo que se encuentra disponible sólo para un grupo seleccionado de personas.</p>

        <p>Al hacer uso de este formulador se está accediendo a la última versión del simulador del sistema digestivo de la Cornell University, uno de los simuladores líderes en el mundo que busca mediante la alta precisión en el diseño de una dieta, obtener ahorros importantes en los costos de alimentación.</p>

        <p>Durante el periodo de pruebas el servicio puede tener interrupciones y en el caso de una alta demanda de uso puede tardar minutos en entregar un resultado.</p>

        <p>Alisur recomienda que el uso de ésta herramienta sea monitoreada por un profesional de la nutrición que pueda determinar el correcto balance de la dieta y de asignar los márgenes de seguridad de aportes nutricionales requeridos.</p>
      </div>
    </info-modal>
    <info-modal
      name="salt-modal"
      title="Información sobre las Sales Minerales"
    >
      <div class="salt-modal-info" />
    </info-modal>
    <info-modal
      name="forage-modal"
      title="Información sobre forrajes"
    >
      <div class="forage-modal-info" />
    </info-modal>
    <info-modal
      name="meadow-modal"
      title="Información sobre praderas"
    >
      <div class="meadow-modal-info" />
    </info-modal>
    <modal-invalid-ingredients />
    <modal-unavailable-ingredients />
    <modal-register />
    <modal-auth :rememberable="rememberable" />
    <modal-history :image="image" />
    <modals-container />
    <div class="home">
      <home-welcome
        :images="homeImages"
        :username="username"
      />
      <div class="content__formulator-container">
        <div class="content__formulator">
          <formulator-input
            :enabled-cncps-formulator="enabledCncpsFormulator"
          />
          <formulator-output />
        </div>
      </div>
      <div
        v-if="!coagraFormulator"
        class="content__promotions"
      >
        <div id="promotions__carousel">
          <promotions />
        </div>
      </div>
      <div
        v-if="!coagraFormulator"
        class="content__materials"
      >
        <div id="raw__materials__carousel">
          <raw-material />
        </div>
      </div>
      <div class="content__links" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import homeWelcome from './home/home-welcome.vue';
import formulatorInput from './formulator/formulator-input.vue';
import formulatorOutput from './formulator/formulator-output.vue';
import promotions from './promotions.vue';
import rawMaterial from './raw-materials.vue';
import store from './store';
import modalProduct from './modal-product';
import infoModal from './modals/info-modal';
import modalInvalidIngredients from './modal-invalid-ingredients.vue';
import modalUnavailableIngredients from './modal-unavailable-ingredients.vue';
import modalRegister from './modal-register';
import modalAuth from './modal-auth';
import modalHistory from './modal-history';

export default {
  store,
  components: {
    homeWelcome,
    formulatorInput,
    formulatorOutput,
    promotions,
    rawMaterial,
    modalProduct,
    infoModal,
    modalInvalidIngredients,
    modalUnavailableIngredients,
    modalRegister,
    modalAuth,
    modalHistory,
  },
  props: {
    username: {
      type: String,
      default: '',
    },
    rememberable: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
    homeImages: {
      type: String,
      default: '',
    },
    coagraFormulator: {
      type: Boolean,
      default: false,
    },
    enabledCncpsFormulator: {
      type: Boolean,
      default: false,
    },
    ingredientsProp: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.$store.dispatch('setIngredients', this.ingredientsProp);
    this.$store.dispatch('updateIsCoagraWeb', {
      isCoagraWeb: this.coagraFormulator,
    });
  },
  mounted() {
    const header = document.getElementsByTagName('header')[0];
    header.classList.add('header--home');
  },
  methods: {
    getProducts() {
      axios.get('/api/v1/products.json')
        .then(response => {
          const products = response.data;
          products.forEach((product) => {
            // eslint-disable-next-line camelcase
            product.kilogram_price = parseFloat(product.kilogram_price);
          });
          this.$store.state.products = products;
        })
        .catch(e => {
          alert(e);
        });
    },
  },
};
</script>
