export default {
  state: {
    viewMode: true,
    wayToPay: '',
    user: {
      name: null,
      surname: null,
      numberPrincipal: null,
      numberSecondary: null,
      bname: null,
      rut: null,
      creditAllowed: false,
      bcategory: null,
      baddress: null,
    },
    address: {
      marker: {
        position: { lat: -40.484364, lng: -73.029934 },
      },
      reference: null,
      name: null,
    },
    disabledDates: [],
    datesWithFees: {},
    lastDate: null,
    desiredDate: '',
    services: {
      freight: 0,
      date: 0,
      sackDischarge: 0,
    },
    formulaName: null,
    isCoagraWeb: false,
  },
  mutations: {
    updateContactUserInfo: (state, payload) => {
      if (payload.name) {
        state.user.name = payload.name;
      } else if (payload.numberPrincipal) {
        state.user.numberPrincipal = payload.numberPrincipal;
      } else if (payload.numberSecondary) {
        state.user.numberSecondary = payload.numberSecondary;
      } else if (payload.surname) {
        state.user.surname = payload.surname;
      } else if (payload.rut) {
        state.user.rut = payload.rut;
      } else if (payload.bname) {
        state.user.bname = payload.bname;
      } else if ('creditAllowed' in payload) {
        state.user.creditAllowed = payload.creditAllowed;
      } else if ('bcategory' in payload) {
        state.user.bcategory = payload.bcategory;
      } else if ('baddress' in payload) {
        state.user.baddress = payload.baddress;
      }
    },
    updateContactUserAddressMarker: (state, payload) => {
      state.address.marker.position = payload;
    },
    updateContactUserAddressReference: (state, payload) => {
      state.address.reference = payload;
    },
    updateContactUserAddressName: (state, payload) => {
      state.address.name = payload;
    },
    updateContactUserServiceFreight: (state, payload) => {
      state.services.freight = payload;
    },
    updateDate: (state, payload) => {
      state.desiredDate = payload;
    },
    updateDateService: (state, payload) => { state.services.date = payload; },
    updateDisabledDates: (state, payload) => { state.disabledDates = payload; },
    updateDatesWithFees: (state, payload) => { state.datesWithFees = payload; },
    updateLastDate: (state, payload) => { state.lastDate = payload; },
    updateViewMode: (state, payload) => { state.viewMode = payload; },
    updateWayToPay: (state, payload) => { state.wayToPay = payload; },
    updateFormulaName: (state, payload) => { state.formulaName = payload; },
    updateSackDischargeService: (state, payload) => { state.services.sackDischarge = payload; },
    updateIsCoagraWeb: (state, payload) => { state.isCoagraWeb = payload.isCoagraWeb; },
  },
  actions: {
    updateContactUserAddressReference: (context, payload) => {
      context.commit('updateContactUserAddressReference', payload);
    },
    updateContactUserAddressName: (context, payload) => {
      context.commit('updateContactUserAddressName', payload);
    },
    updateContactUserAddressMarker: (context, payload) => {
      context.commit('updateContactUserAddressMarker', payload);
    },
    updateContactUserServiceFreight: (context, payload) => {
      context.commit('updateContactUserServiceFreight', payload);
    },
    updateContactUserInfo: (context, payload) => {
      context.commit('updateContactUserInfo', payload);
    },
    updateDate: (context, payload) => {
      context.commit('updateDate', payload);
      context.commit('updateDateService', context.getters.datePrice(payload));
    },
    searchAddress: (context, payload) => {
      api.searchAddress(payload)
        .then((response) => {
          context.commit('updateContactUserAddressReference', response);
        })
        .catch((e) => console.log(e));
    },
    updateSackDischargeService: (context, payload) => {
      context.commit('updateSackDischargeService', payload);
    },
    updateIsCoagraWeb: (context, payload) => {
      context.commit('updateIsCoagraWeb', payload);
    },
  },
};
