<template>
  <div class="nutritional-information__row">
    <div class="nutritional-information__cell">
      {{ value.toUpperCase() }}
    </div>
    <div class="nutritional-information__cell">
      <nutritional-info :property="value" :value="nutritionalInfo[value]"></nutritional-info>
    </div>
  </div>
</template>
<script>
import VueNumeric from 'vue-numeric';
import currency from '../../../tools/currency';
import percentage from '../../../tools/percentage';
import nutritionalInfo from '../../../tools/nutritional-info';

export default {
  components: {
    VueNumeric,
    currency,
    percentage,
    nutritionalInfo,
  },
  data() {
    return {};
  },
  props: ['value'],
  computed: {
    nutritionalInfo() {
      return this.$store.state.recipe.nutritionalInfo;
    },
  },
};
</script>

