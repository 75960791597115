<template>
  <div v-on-clickaway="hide">
    <div class="header__user__link btn btn-dark" @click="toggleShow" >Iniciar sesión</div>
    <div class="login" v-show="show">
      <div class="login__label-input">
        <label>Correo </label><br/>
        <input class="login__input" type="email" autofocus name="user[email]" v-model="email"><br/>
      </div>
      <div class="login__label-input">
        <label>Clave </label><br/>
        <input class="login__input" type="password" autocomplete="off" v-model="password" @keyup.enter="signIn"><br/>
      </div>
      <error
        class="error__login"
        :err="credentialErrors"
        attribute="credentials"
      />
      <div class="login__submit">
        <button class="btn btn--wide" @click="signIn">Ingresar</button><br/>
      </div>

      <div class="login__remember-me">
        <input type="checkbox" name="user[remember_me]" id="user_remember_me" v-if="rememberable" v-model="rememberMe">
        <label class="login__remember-me-label" for="user_remember_me" v-if="rememberable">Recordarme</label>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import axios from 'axios';
import error from '../tools/error';

export default {
  components: {
    error,
  },
  props: ['rememberable'],
  mixins: [clickaway],
  data() {
    return {
      show: false,
      credentialErrors: {},
      email: '',
      password: '',
      rememberMe: false,
    };
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    hide() {
      this.show = false;
    },
    signIn() {
      const params = { email: this.email, password: this.password, 'remember_me': this.rememberMe ? 1 : 0 };
      const that = this;

      return axios.post('/users/sign_in', {
        user: params,
      }, {
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      }).then(() => {
        window.location.href = '/';
      }).catch(e => {
        that.credentialErrors = e.response.data.errors;
      });
    },
  },
  watch: {
    show() {
      this.$store.commit('updateShowOverlay', this.show);
    },
  },
};
</script>
