<template>
  <div class="order-new__date">
    <v-date-picker
      tint-color="#50bfa3"
      v-model="selectedDate"
      :min-date="new Date()"
      :max-date="lastDate"
      :disabled-dates="disabledDates"
      :show-linked-buttons="false"
      :rows="2"
      :columns="2"
      :attributes="attributes"
      :locale="locale"
      color="teal"
      is-inline
      is-linked
      v-if="lastDate"
    ></v-date-picker>
    <div
      v-if="addressName === null"
    >
      * Para poder seleccionar la fecha de entrega debe haber seleccionado un lugar de despacho válido
    </div>
  </div>
</template>
<script>
/* eslint-disable no-magic-numbers */

import moment from 'moment';

import store from '../store';
import currency from '../../tools/currency';

export default {
  store,
  components: {
    currency,
  },
  data() {
    return {
      services: 0,
      mode: 'single',
      selectedDate: null,
      locale: {
        id: 'es',
        firstDayOfWeek: 2,
      },
    };
  },
  computed: {
    addressName() {
      return this.$store.state.order.address.name;
    },
    disabledDates() {
      const fullDays = this.$store.state.order.disabledDates.map(d => ({ start: new Date(d), end: new Date(d) }));

      return fullDays;
    },
    lastDate() {
      const lastDate = this.$store.state.order.lastDate;

      return lastDate ? moment(lastDate, 'YYYY-MM-DD').toDate() : null;
    },
    attributes() {
      return [
        {
          bar: {
            color: 'gray',
          },
          dates: this.$store.getters.dateArrayWithFees,
          popover: {
            label: 'Pedido urgente',
          },
        },
      ];
    },
  },
  watch: {
    selectedDate(newDate) {
      this.$store.dispatch('updateDate', newDate);
    },
  },
};
</script>

<style scoped lang="scss">
.vc-text-gray-500 {
  color: #58585b;
}

.vc-text-gray-400 {
  color: #ccc;
}

.vc-container {
  border: none;
}
</style>
