<template>
  <div class="c-table-info c-table-info--bordered">
    <h2>{{ $t('user.userTitle') }}</h2>
    <div class="c-table-info__row">
      <div class="c-table-info__cell">
        <div class="c-table-info__title">{{ $t('user.attributes.name') }}</div>
        <div class="c-table-info__value" v-if="!editContactInfoMode">{{ tmpUser.name }}</div>
        <template v-else>
          <input class="input" v-model="tmpUser.name" name="userName">
          <error :err="contactErrors" attribute="name"></error>
        </template>
      </div>
      <div class="c-table-info__cell">
        <div class="c-table-info__title">{{ $t('user.attributes.surname') }}</div>
        <div class="c-table-info__value" v-if="!editContactInfoMode">{{ persistedUser.surname }}</div>
        <template v-else>
          <input class="input" v-model="tmpUser.surname" name="userSurname">
          <error :err="contactErrors" attribute="surname"></error>
        </template>
      </div>
      <div class="c-table-info__cell">
        <div class="c-table-info__title">{{ $t('user.attributes.email') }}</div>
        <div class="c-table-info__value" >{{ persistedUser.email }}</div>
      </div>
    </div>
    <div class="c-table-info__row">
      <div class="c-table-info__cell">
        <div class="c-table-info__title">{{ $t('user.attributes.number1') }}</div>
        <div class="c-table-info__value" v-if="!editContactInfoMode">{{ persistedUser.number1 }}</div>
        <template v-else>
          <input class="input" v-model="tmpUser.number1" name="userNumber1">
          <error :err="contactErrors" attribute="number_1"></error>
        </template>
      </div>
      <div class="c-table-info__cell">
        <div class="c-table-info__title">{{ $t('user.attributes.number2') }}</div>
        <div class="c-table-info__value" v-if="!editContactInfoMode">{{ persistedUser.number2 }}</div>
        <template v-else>
          <input class="input" v-model="tmpUser.number2" name="userNumber2">
          <error :err="contactErrors" attribute="number_2"></error>
        </template>
      </div>
      <div class="c-table-info__cell">
        <button @click="editContactInfoMode = true" class="btn c-table-info__edit-btn" v-if="!editContactInfoMode">Editar</button>
        <template v-else>
          <button @click="editContactInfo" class="btn">Guardar</button>
          <button @click="cancelEditContactInfoMode" class="btn btn-outline">Cancelar</button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import humps from 'humps';
import error from '../../tools/error';

export default {
  props: ['user'],
  components: {
    error,
  },
  data() {
    return {
      editContactInfoMode: false,
      tmpUser: {
        name: this.user.name,
        surname: this.user.surname,
        email: this.user.email,
        number1: this.user.number_1,
        number2: this.user.number_2,
      },
      persistedUser: {
        name: this.user.name,
        surname: this.user.surname,
        email: this.user.email,
        number1: this.user.number_1,
        number2: this.user.number_2,
      },
      contactErrors: {},
    };
  },
  methods: {
    resetTmpUser() {
      this.tmpUser.name = this.persistedUser.name;
      this.tmpUser.surname = this.persistedUser.surname;
      this.tmpUser.email = this.persistedUser.email;
      this.tmpUser.number1 = this.persistedUser.number1;
      this.tmpUser.number2 = this.persistedUser.number2;
    },
    resetContactErrors() {
      this.contactErrors = {};
    },
    setPersistedUser() {
      this.persistedUser.name = this.tmpUser.name;
      this.persistedUser.surname = this.tmpUser.surname;
      this.persistedUser.email = this.tmpUser.email;
      this.persistedUser.number1 = this.tmpUser.number1;
      this.persistedUser.number2 = this.tmpUser.number2;
    },
    editContactInfo() {
      const that = this;

      axios.patch('/api/v1/profile', {
        user: humps.decamelizeKeys(this.tmpUser, { split: /(?=[A-Z0-9])/ }),
      }, {
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      }).then(() => {
        that.editContactInfoMode = false;
        that.setPersistedUser();
        that.resetContactErrors();
      }).catch((e) => {
        that.contactErrors = e.response.data.errors;
      });
    },
    cancelEditContactInfoMode() {
      this.resetTmpUser();
      this.resetContactErrors();
      this.editContactInfoMode = false;
    },
  },
};
</script>
