<template>
  <div class="formulator-input__container">
    <div
      id="formulator-input"
      class="formulator-input"
    >
      <div class="formulator-input__header">
        <h2 class="formulator-input__title">
          <span>Formula tu alimento balanceado</span>
        </h2>
        <div class="input__tabs">
          <div
            v-for="tab in filteredTabs"
            v-html="tab.label"
            :key="`tab-${tab.id}`"
            class="input__tab"
            @click="setSelectedTab(tab.id)"
            :class="selectedTab === tab.id ? 'input__tab--active' : 'input__tab--inactive'"
          />
        </div>
      </div>
      <div class="input__restrictions">
        <div
          class="restriction__high-precision"
          v-if="selectedTab === 'high-precision'"
        >
          <formulator-high-precision />
        </div>
        <div
          class="restriction__nutritional"
          v-if="selectedTab === 'nutritional'"
        >
          <formulator-nutrition />
        </div>
        <div
          class="restriction__ingredient"
          v-if="selectedTab === 'ingredient'"
        >
          <formulator-ingredients />
        </div>
        <div
          class="restriction__coagra"
          v-if="selectedTab === 'coagra'"
        >
          <formulator-ingredients />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formulatorNutrition from './formulator-nutrition';
import formulatorIngredients from './formulator-ingredients.vue';
import formulatorHighPrecision from './formulator-high-precision.vue';

export default {
  components: {
    formulatorNutrition,
    formulatorIngredients,
    formulatorHighPrecision,
  },
  props: {
    enabledCncpsFormulator: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setSelectedTab(tabName) {
      this.$store.commit('setSelectedTab', tabName);
    },
    openInfo(infoModal) {
      this.$modal.show(infoModal);
    },
    setDefaultTab() {
      let form = 'nutritional';
      if (this.showCoagraFormulator) {
        form = 'coagra';
      } else if (this.highPrecisionEnabled) {
        form = 'high-precision';
      }
      this.setSelectedTab(form);
    },
  },
  computed: {
    showCoagraFormulator() {
      return this.$store.state.order.isCoagraWeb;
    },
    selectedTab() {
      return this.$store.state.formulator.selectedTab;
    },
    tabs() {
      const tabs = [
        { id: 'high-precision', label: 'Alta <br/> precisión', active: this.highPrecisionEnabled },
      ];

      if (this.showCoagraFormulator) {
        tabs.push({ id: 'coagra', label: 'Formulador Coagra', active: true });
      } else {
        tabs.push({ id: 'nutritional', label: 'Restricciones nutricionales', active: true });
        tabs.push({ id: 'ingredient', label: 'Restricción ingredientes', active: true });
      }

      return tabs;
    },
    filteredTabs() {
      return this.tabs.filter(tab => tab.active);
    },
    highPrecisionEnabled() {
      return this.enabledCncpsFormulator && this.loggedIn;
    },
    loggedIn() {
      return this.$store.state.loggedIn;
    },
  },
  mounted() {
    if (this.selectedTab) return;

    this.setDefaultTab();
  },
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
