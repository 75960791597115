<template>
  <div>
    <div class="new-order__formula">
      <table class="new-order__formula-section">
        <thead>
          <tr>
            <th>Info. Nutricional</th>
            <th>BMS</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, key) in nutritionalInfo"
            :key="key"
          >
            <td>{{ key.toUpperCase() }}</td>
            <td>
              <nutritional-info
                :property="key"
                :value="value"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table class="new-order__formula-section">
        <thead>
          <tr>
            <th>Info. Ingredientes</th>
            <th>TCO</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, key) in positiveIngredientsProportion"
            :key="key"
          >
            <td>{{ key }}</td>
            <td>
              <percentage
                :value="value"
                :precision="1"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import pickBy from 'lodash/pickBy';
import percentage from '../../tools/percentage';
import nutritionalInfo from '../../tools/nutritional-info';

export default {
  components: {
    percentage,
    nutritionalInfo,
  },
  computed: {
    price() {
      return this.$store.state.recipe.price;
    },
    weight() {
      return this.$store.state.recipe.weight;
    },
    purchaseValue() {
      return this.price * this.weight;
    },
    nutritionalInfo() {
      return this.$store.state.recipe.nutritionalInfo;
    },
    ingredientsProportion() {
      return this.$store.state.recipe.ingredientsProportion;
    },
    positiveIngredientsProportion() {
      return pickBy(this.ingredientsProportion, (value) => value > 0);
    },
  },
};
</script>
