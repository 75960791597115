<template>
  <div class="c-table-info c-table-info--bordered">
    <h2>{{ $t('user.businessTitle') }}</h2>
    <div class="c-table-info__row">
      <div class="c-table-info__cell">
        <div class="c-table-info__title">{{ $t('user.attributes.billingAttributes.name') }}</div>
        <ev-select class="order-new__business-name-dropdown"
                    v-model="selectedBillingAddress"
                    label="name"
                    :options="billingAddresses"
                    v-if="!editBillingInfoMode">
        </ev-select>
        <template v-else>
          <input class="input" v-model="tmpBillingAddress.name" name="billingName">
          <error :err="billingErrors" attribute="name"></error>
        </template>
      </div>
      <div class="c-table-info__cell">
        <div class="c-table-info__title">{{ $t('user.attributes.billingAttributes.rut') }}</div>
        <div class="c-table-info__value" v-if="!editBillingInfoMode">{{ selectedBillingAddress.rut }}</div>
        <template v-else>
          <input class="input" v-model="tmpBillingAddress.rut" name="billingRut">
          <error :err="billingErrors" attribute="rut"></error>
        </template>
      </div>
      <div class="c-table-info__cell">
        <button @click="editBillingInfoMode = true" class="btn c-table-info__edit-btn" v-if="!editBillingInfoMode">Editar</button>
        <template v-else>
          <button @click="editBillingInfo" class="btn">Guardar</button>
          <button @click="cancelEditBillingInfoMode" class="btn btn-outline">Cancelar</button>
        </template>
      </div>
    </div>
    <div class="c-table-info__row">
      <div class="c-table-info__cell">
        <div class="c-table-info__title">{{ $t('user.attributes.billingAttributes.businessAddress') }}</div>
        <div class="c-table-info__value" v-if="!editBillingInfoMode">
          {{ selectedBillingAddress.business_address }}
        </div>
        <template v-else>
          <input class="input" v-model="tmpBillingAddress.business_address" name="billingBusinessAddress">
          <error :err="billingErrors" attribute="business_address"></error>
        </template>
      </div>
      <div class="c-table-info__cell">
        <div class="c-table-info__title">{{ $t('user.attributes.billingAttributes.businessCategory') }}</div>
        <div class="c-table-info__value" v-if="!editBillingInfoMode">{{ selectedBillingAddress.business_category }}</div>
        <template v-else>
          <input class="input" v-model="tmpBillingAddress.business_category" name="billingBusinessCategory">
          <error :err="billingErrors" attribute="business_category"></error>
        </template>
      </div>
      <div class="c-table-info__cell">
        <button :disabled="hasNoBillingAddresses" @click="deleteBillingAddress" class="btn c-table-info__edit-btn">Eliminar</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import error from '../../tools/error';
import evSelect from '../ev-select.vue';

export default {
  props: ['addresses'],
  components: {
    error,
    evSelect,
  },
  data() {
    return {
      editBillingInfoMode: false,
      selectedBillingAddress: { ...this.addresses[0] },
      tmpBillingAddress: { ...this.addresses[0] },
      billingErrors: {},
      billingAddresses: this.addresses,
    };
  },
  computed: {
    hasNoBillingAddresses() {
      return this.billingAddresses.length === 0 ||
        (this.billingAddresses.length === 1 && !this.billingAddresses[0].id);
    },
  },
  mounted() {
    if (this.billingAddresses.length === 0) {
      this.setEmptyBillingAddresses();
    }
  },
  methods: {
    setEmptyBillingAddresses() {
      this.billingAddresses.push({
        name: 'Ejemplo: Alisur S.A',
        rut: '',
        'business_address': '',
        'business_category': '',
      });
      this.selectedBillingAddress = { ...this.billingAddresses[0] };
    },
    setTmpBillingAddress() {
      this.tmpBillingAddress = { ...this.selectedBillingAddress };
    },
    setSelectedBillingAddress(newAddress) {
      let targetAddressIndex;
      if (this.hasNoBillingAddresses) {
        targetAddressIndex = 0;
      } else {
        targetAddressIndex = this.billingAddresses.findIndex((address) => address.id === this.selectedBillingAddress.id);
      }
      this.selectedBillingAddress = { ...newAddress };
      this.$set(this.billingAddresses, targetAddressIndex, this.selectedBillingAddress);
    },
    deleteSelectedBillingAddress(newAddresses) {
      this.billingAddresses = newAddresses;
      if (newAddresses.length === 0) {
        this.setEmptyBillingAddresses();
      }
      this.tmpBillingAddress = { ...this.billingAddresses[0] };
      this.selectedBillingAddress = { ...this.billingAddresses[0] };
    },
    resetBillingErrors() {
      this.billingErrors = {};
    },
    editBillingInfo() {
      const that = this;
      const requestParams = ['/api/v1/profile/billing_address', {
        billing: this.tmpBillingAddress,
      }, {
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      }];
      let axiosPromise;

      if (this.hasNoBillingAddresses) {
        axiosPromise = axios.post(...requestParams);
      } else {
        axiosPromise = axios.put(...requestParams);
      }

      axiosPromise.then((response) => {
        that.editBillingInfoMode = false;
        that.setSelectedBillingAddress(response.data.address);
        that.resetBillingErrors();
      }).catch((e) => {
        that.billingErrors = e.response.data.errors;
      });
    },
    deleteBillingAddress() {
      const that = this;

      axios.delete('/api/v1/profile/billing_address', {
        data: {
          billing: { id: this.selectedBillingAddress.id },
        },
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      }).then((response) => {
        that.deleteSelectedBillingAddress(response.data.addresses);
      }).catch((e) => {
        that.billingErrors = e.response.data.errors;
      });
    },
    cancelEditBillingInfoMode() {
      this.setTmpBillingAddress();
      this.resetBillingErrors();
      this.editBillingInfoMode = false;
    },
  },
  watch: {
    selectedBillingAddress() {
      this.setTmpBillingAddress();
    },
  },
};
</script>
