<template>
  <div class="ingredient-content">
    <div class="ingredient-content__title">
      Contenido ingredientes (TCO)
    </div>
    <div class="ingredient-content__table">
      <div class="ingredient-content__row" v-for="(value, key) in ingredientsProportion">
        <div class="ingredient-content__column">
          {{ key }}
        </div>
        <div class="ingredient-content__column">
          <percentage :value="Number(value)" :precision="1"></percentage>
        </div>
      </div>
    </div>
    <div class="ingredient-content__resume">
      <div class="ingredient-content__total">
        Total
      </div>
      <div class="ingredient-content__total">
        <percentage
          v-if="hasIngredients"
          :value="100"/>
        <span v-if="!hasIngredients">-</span>
      </div>
    </div>
  </div>
</template>
<script>
import VueNumeric from 'vue-numeric';
import currency from '../../../tools/currency';
import percentage from '../../../tools/percentage';
import nutritionalInfo from '../../../tools/nutritional-info';

export default {
  components: {
    VueNumeric,
    currency,
    percentage,
    nutritionalInfo,
  },
  data() {
    return {};
  },
  computed: {
    ingredientsProportion() {
      return this.$store.state.recipe.ingredientsProportion;
    },
    hasIngredients() {
      return Object.keys(this.ingredientsProportion).length > 0;
    },
  },
};
</script>
