<template>
  <modal name="conditional-modal"         
         :scrollable="true"
         :max-width="1000"
         width="35%"
         height="auto"
         @before-open="beforeOpen">
    <div class="product-modal" v-if="details">
      <div class="product-modal-bar">
        <i class="material-icons product-modal-close" v-on:click="$modal.hide('conditional-modal')">highlight_off</i>
      </div>
      <div class="product-modal-header">
        <h3 class="product-modal-header__name">{{ details.name}}</h3>
        <div class="product-modal-header__price">
          Valor Neto <b><currency :value="details.kilogram_price"></currency></b> x kg<br/>
          Peso Total <vue-numeric separator="." v-bind:read-only="true" :value="details.unit_tons * 1000"></vue-numeric> kg<br/>
          Sub total <currency :value="details.kilogram_price * details.unit_tons * 1000"></currency> <br/>
        </div>
      </div>
      <hr />
      <div v-if="details.description">
        <b>Descripci&oacute;n</b>
        <p>{{ details.description }}</p>
      </div>
      <div>
        <b>Presentaci&oacute;n</b>
        <p v-if="details.form || details.packaging">{{ translate(details.form) }} y  {{ translate(details.packaging) }}</p>
        <p v-else>N/A</p>
      </div>
      <b>Informaci&oacute;n del Producto</b>
      <div class="product-modal-info">
        <div class="product-modal-info__nutritional">
          <h4 class="uppercase">Informaci&oacute;n Nutricional* (BMS)</h4>
          <div class="product-modal-info__nutritional__data">
            <table>
              <tr v-for="property in nutritionalInfo0">
                <td class="title">{{ property.name }}</td>
                <td class="value"><nutritional-info :property="property.name" :value="property.value"></nutritional-info></td>
              </tr>
            </table>
            <table>
              <tr v-for="property in nutritionalInfo1">
                <td class="title">{{ property.name }}</td>
                <td class="value"><nutritional-info :property="property.name" :value="property.value"></nutritional-info></td>
              </tr>
            </table>
          </div>
          *valores excluyen sales
        </div>
        <div class="product-modal-info__ingredients">
          <h4 class="uppercase">Contenido Ingredientes (TCO)</h4>
          <div class="product-modal-info__ingredients__data">
            <table>
              <tr v-for="ingredient in details.product_ingredients">
                <td class="title">{{ ingredient.name }}</td>
                <td class="value">
                  <percentage :value="parseFloat(ingredient.percentage)" :precision="1"></percentage>
                </td>
              </tr>
              <tr>
                <td class="title total">Total</td>
                <td class="value total"><percentage :value="100" :precision="1"></percentage></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="btns">
        <span class="btn btn-outline" v-on:click="$modal.hide('conditional-modal')">Cerrar</span>
        <span class="btn" v-on:click="buy">Comprar</span>
      </div>
    </div>
  </modal>
</template>
<script>
  import VueNumeric from 'vue-numeric';

  import api from './store/api';
  import helpers from './helpers';
  import currency from '../tools/currency';
  import percentage from '../tools/percentage';
  import nutritionalInfo from '../tools/nutritional-info';

  export default {
    components: {
      VueNumeric,
      currency,
      percentage,
      nutritionalInfo,
    },
    name: 'ConditionalModal',
    data() {
      return {
        details: null,
        nutritionalInfo0: null,
        nutritionalInfo1: null
      };
    },    
    methods: {
      beforeOpen(event) {
        api.getProductDetails(event.params.product.id)
           .then((response) => {
             this.details = response;
             this.details.kilogram_price = parseFloat(this.details.kilogram_price);
             this.nutritionalInfo0 = [{ name: 'pc', value: response.pc },
                                      { name: 'em', value: response.em },
                                      { name: 'cne', value: response.cne },
                                      { name: 'ee', value: response.ee }];
             this.nutritionalInfo1 = [{ name: 'fdn', value: response.fdn },
                                      { name: 'ct', value: response.ct },
                                      { name: 'fc', value: response.fc },
                                      { name: 'ms', value: response.ms }];
           });
      },
      translate(word) {
        return helpers.translate(word);
      },
      buy() {
        window.location.href = `/order/new?product=${this.details.id}`;
      },
    }
  }
</script>

