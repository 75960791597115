<template>
  <div class="nutritional-information">
    <div class="nutritional-information__title">
      Información nutricional* (BMS)
    </div>
    <div class="nutritional-information__table">
      <div class="nutritional-information__column">
        <nutritional-row :value="'pc'"></nutritional-row>
        <nutritional-row :value="'em'"></nutritional-row>
        <nutritional-row :value="'cne'"></nutritional-row>
        <nutritional-row :value="'ee'"></nutritional-row>
      </div>
      <div class="nutritional-information__column">
        <nutritional-row :value="'fdn'"></nutritional-row>
        <nutritional-row :value="'ct'"></nutritional-row>
        <nutritional-row :value="'fc'"></nutritional-row>
        <nutritional-row :value="'ms'"></nutritional-row>
      </div>
    </div>
    <div class="nutritional-information__disclaimer">
      *Valores excluyen sales
    </div>
  </div>
</template>
<script>
import nutritionalRow from './nutritional-row.vue';

export default {
  components: {
    nutritionalRow,
  },
  data() {
    return {};
  },
};
</script>
