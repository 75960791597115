export default {
  user: {
    userTitle: 'Contact Information',
    businessTitle: 'Billing Details',
    securityTitle: 'Security',
    attributes: {
      name: 'Name',
      surname: 'Surname',
      email: 'Email',
      number1: 'Number 1',
      number2: 'Number 2',
      billingAttributes: {
        rut: 'Company RUT',
        billingName: 'Company Name',
        businessCategory: 'Category',
        businessAddress: 'Company address',
      },
    },
    security: {
      currentPassword: 'Current Password',
      password: 'New Password',
      passwordConfirmation: 'Password Confirmation',
    },
  },
};
