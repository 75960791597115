<template>
  <div
    id="formulator__output"
    class="formulator__output"
  >
    <info />
    <hr class="output-separator">
    <div class="l-output-information">
      <nutritional-information />
      <hr class="output-separator">
      <ingredient-content />
      <div v-if="selectedTab === 'high-precision'">
        <hr class="output-separator">
        <goals />
      </div>
      <div v-if="showWeightRequirement">
        <hr class="output-separator">
        <weight-required />
      </div>
    </div>
    <hr class="output-separator">
    <div class="output__button">
      <button
        class="formulator__button"
        @click="checkIfAuth"
        :disabled="!updated"
      >
        Continuar
      </button>
    </div>
  </div>
</template>
<script>
import store from '../store';
import helper from '../helpers';
import info from './output/info.vue';
import nutritionalInformation from './output/nutritional-information.vue';
import ingredientContent from './output/ingredient-content.vue';
import WeightRequired from './output/weight-required.vue';
import goals from './output/goals.vue';

export default {
  store,
  components: {
    info,
    nutritionalInformation,
    ingredientContent,
    WeightRequired,
    goals,
  },
  methods: {
    checkIfAuth() {
      if (this.loggedIn) {
        this.goToNewOrder();
      } else {
        this.$store.state.nextPath = this.newOrderPath;
        this.showAuthModal();
      }
    },
    showAuthModal() {
      this.$modal.show('auth-modal');
    },
    goToNewOrder() {
      window.location.href = this.newOrderPath;
    },
  },
  computed: {
    updated() {
      return this.$store.state.recipe.updated;
    },
    dailyWeightRequirement() {
      return this.$store.state.recipe.dailyWeightRequirement;
    },
    showWeightRequirement() {
      return this.selectedTab === 'high-precision';
    },
    selectedTab() {
      return this.$store.state.formulator.selectedTab;
    },
    dairyInfo() {
      return this.$store.state.formulator.dairyInfo;
    },
    newOrderPath() {
      let formulatorParams = helper.serializeQuery(this.$store.getters.params, 'ingredients_proportion');
      formulatorParams += `&${helper.serializeQuery(this.$store.state.recipe.nutritionalInfo, 'nutritional_info')}`;
      formulatorParams += `&form=${this.$store.state.recipe.finishing.form.value}`;
      formulatorParams += `&packaging=${this.$store.state.recipe.finishing.packaging.value}`;
      const totalSackWeight = this.$store.state.recipe.finishing.totalSackWeight;
      if (totalSackWeight) formulatorParams += `&total_sack_weight=${totalSackWeight}`;
      const via = this.$store.state.via;
      formulatorParams += `&via=${via}`;

      return `/order/new?${formulatorParams}`;
    },
    loggedIn() {
      return this.$store.state.loggedIn;
    },
  },
};
</script>
