<template>
  <modal
    name="auth-modal"
    :scrollable="true"
    :max-width="1000"
    width="70%"
    height="auto">
    <div class="register-modal">
      <div class="auth-modal-bar">
        <div class="auth-modal-bar__title">
          Inicia sesión
        </div>
        <div class="auth-modal-bar__item">
          ó
        </div>
        <div class="auth-modal-bar__title">
          Quiero saber más...
        </div>
        <div class="auth-modal-bar__item">
          <i
            class="material-icons register-modal-close"
            @click="$modal.hide('auth-modal')"
          >highlight_off</i>
        </div>
      </div>
      <div class="auth-modal-body">
        <div class="auth-modal-login">
          <div class="login__label-input">
            <label>Correo </label><br>
            <input
              class="login__input"
              type="email"
              autofocus
              name="user[email]"
              v-model="emailLogin"
            ><br>
          </div>
          <div class="login__label-input">
            <label>Clave </label><br>
            <input
              class="login__input"
              type="password"
              autocomplete="off"
              v-model="passwordLogin"
              @keyup.enter="SignIn"
            ><br>
          </div>
          <error
            :err="credentialErrors"
            attribute="credentials"
          />
          <div class="login__submit">
            <button
              class="btn btn--wide"
              @click="SignIn"
            >
              Ingresar
            </button><br>
          </div>

          <div class="login__remember-me">
            <input
              type="checkbox"
              name="user[remember_me]"
              id="user_remember_me_mix"
              v-if="rememberable"
              v-model="rememberMe"
            >
            <label
              class="login__remember-me-label"
              for="user_remember_me_mix"
              v-if="rememberable"
            >
              Recordarme
            </label>
          </div>
        </div>
        <div class="auth-modal-register">
          <register-form :mix="true" />
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import axios from 'axios';
import error from '../tools/error';

import RegisterForm from './register-form.vue';

export default {
  name: 'AuthModal',
  components: {
    error,
    RegisterForm,
  },
  props: {
    rememberable: { type: Boolean, required: true },
  },
  mixins: [clickaway],
  data() {
    return {
      emailLogin: '',
      passwordLogin: '',
      credentialErrors: {},
      rememberMe: false,
    };
  },
  methods: {
    SignIn() {
      const params = { email: this.emailLogin, password: this.passwordLogin, 'remember_me': this.rememberMe ? 1 : 0 };
      const that = this;

      return axios.post('/users/sign_in', {
        user: params,
      }, {
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      }).then(() => {
        window.location.href = '/';
      }).catch(e => {
        that.credentialErrors = e.response.data.errors;
      });
    },
  },
};
</script>
