<template>
  <div id="user-info">
    <div class="user-info--view-mode">
      <div class="user-info">
        <div class="user-info__contact">
          <h3 class="user-info__subtitle">Datos de contacto</h3>
          <div class="user-info__table">
            <div class="user-info__table-row">
              <div class="user-info__table-data">Nombre</div>
              <div class="user-info__table-data">Apellido</div>
            </div>
            <div class="user-info__table-row">
              <div class="user-info__table-data">{{ name }}</div>
              <div class="user-info__table-data">{{ surname }}</div>
            </div>
            <div class="user-info__table-row">
              <div class="user-info__table-data">Correo electrónico</div>
              <div class="user-info__table-data"></div>
            </div>
            <div class="user-info__table-row">
              <div class="user-info__table-data">{{ email }}</div>
              <div class="user-info__table-data"></div>
            </div>
            <div class="user-info__table-row">
              <div class="user-info__table-data">Teléfono</div>
              <div class="user-info__table-data">Teléfono Alternativo</div>
            </div>
            <div class="user-info__table-row">
              <div class="user-info__table-data">+56 {{ phone1 }}</div>
              <div class="user-info__table-data">
                <span v-if="phone2 != null && phone2 != ''">+56 {{ phone2 }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="user-info__enterprise">
          <div class="user-info__table">
            <div class="user-info__table-row">
              <div class="user-info__table-data">
                <h3 class="user-info__subtitle">Información de facturación</h3>
              </div>
            </div>
          </div>
          <div class="user-info__table">
            <div class="user-info__table-row" v-show="viewMode">
              <div class="user-info__table-data">Nombre Empresa</div>
              <div class="user-info__table-data">RUT</div>
            </div>
            <div class="user-info__table-row" v-if="viewMode">
              <div class="user-info__table-data">
                <ev-select class="order-new__business-name-dropdown"
                           v-model="selected"
                           label="name"
                           :options="bussinessAddresses">
                </ev-select>
              </div>
              <div class="user-info__table-data">{{ tmpRut }}</div>
            </div>
            <div class="user-info__table-row" v-else>
              <div class="user-info__table-data" v-show="!viewMode">
                <label class="required" for="contact-info-email">Nombre Empresa </label>
                <input class="input" v-model="tmpBname" placeholder="Ejemplo: Alisur S.A." v-validate="'required'" name="tmpBname">
              </div>
              <div class="user-info__table-data" v-show="!viewMode">
                <label class="required" for="contact-info-email">RUT</label>
                <input class="input" v-model="tmpRut" placeholder="Ejemplo: 55.555.555-5" v-validate="'required'" name="tmpRut">
              </div>
            </div>

            <div class="user-info__table-row" v-show="viewMode">
              <div class="user-info__table-data">Giro</div>
              <div class="user-info__table-data">Dirección</div>
            </div>
            <div class="user-info__table-row" v-if="viewMode">
              <div class="user-info__table-data">{{ tmpBcategory }}</div>
              <div class="user-info__table-data">{{ tmpBaddress }}</div>
            </div>
            <div class="user-info__table-row" v-else>
              <div class="user-info__table-data">
                <label class="required" for="tmpBcategory">Giro</label>
                <input class="input" v-model="tmpBcategory" v-validate="'required'" name="tmpBcategory">
              </div>
              <div class="user-info__table-data">
                <label class="required" for="tmpBaddress">Dirección</label>
                <input class="input" v-model="tmpBaddress" v-validate="'required'" name="tmpBaddress">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info" v-show="!viewMode">
        Los <label class="required" ></label> Señalan los campos obligatorios.
        <error class="order-new__error" :err="nameError" attribute="name"></error>
      </div>
      <button class="btn" v-on:click="saveNewBAddress()" :disabled="!canSaveBAddress" v-show="!viewMode">Guardar</button>
      <button class="btn btn-outline" v-on:click="cancelSaveBAddress()" v-show="!viewMode && hasPreviousAddresses">Cancelar</button>
      <button v-on:click="addNewBaddress()" class="btn user-info-btn" v-show="viewMode">Agregar empresa</button>
    </div>
  </div>
</template>
<script>
import evSelect from '../ev-select.vue';
import api from '../store/api';
import error from '../../tools/error';

export default {
  components: {
    evSelect,
    error,
  },
  props: [
    'email',
    'name',
    'surname',
    'phone1',
    'phone2',
    'baddresses',
  ],
  watch: {
    selected: function(newVal, oldVal) {
      this.tmpBname = newVal.name;
      this.tmpRut = newVal.rut;
      this.tmpBcategory = newVal.business_category;
      this.tmpBaddress = newVal.business_address;
      this.updateUserData();
    },
  },
  data() {
    return {
      tmpName: this.name,
      tmpSurname: this.surname,
      tmpPhone1: this.phone1,
      tmpPhone2: this.phone2,
      tmpBname: '',
      tmpRut: '',
      tmpBcategory: '',
      tmpBaddress: '',
      selected: {
        business_address: '',
        business_category: '',
        name: '',
        rut: '',
      },
      bussinessAddresses: [],
      nameError: {},
    };
  },
  created() {
    this.bussinessAddresses = this.baddresses;
    this.updateSelector();
    this.updateUserData();
  },
  computed: {
    viewMode: {
      get() {
        return this.$store.state.order.viewMode;
      },
      set(value) {
        this.$store.commit('updateViewMode', value);
      },
    },
    hasPreviousAddresses() {
      return this.bussinessAddresses.length > 0;
    },
    canSaveBAddress() {
      return this.tmpBname && this.tmpBaddress && this.tmpBcategory && this.tmpRut;
    },
  },
  methods: {
    updateUserData() {
      this.$store.commit('updateContactUserInfo', { name: this.tmpName });
      this.$store.commit('updateContactUserInfo', { surname: this.tmpSurname });
      this.$store.commit('updateContactUserInfo', { numberPrincipal: this.tmpPhone1 });
      this.$store.commit('updateContactUserInfo', { numberSecondary: this.tmpPhone2 });
      this.$store.commit('updateContactUserInfo', { bname: this.tmpBname });
      this.$store.commit('updateContactUserInfo', { rut: this.tmpRut });
      this.$store.commit('updateContactUserInfo', { baddress: this.tmpBaddress });
      this.$store.commit('updateContactUserInfo', { bcategory: this.tmpBcategory });
    },
    initFields() {
      this.tmpName = this.name;
      this.tmpSurname = this.surname;
      this.tmpPhone1 = this.phone1;
      this.tmpPhone2 = this.phone2;
      this.updateSelector();
      this.updateUserData();
      this.nameError = {};
    },
    cancelSaveBAddress() {
      this.viewMode = true;
      this.initFields();
    },
    addNewBaddress() {
      this.viewMode = false;
      this.tmpBname = '';
      this.tmpRut = '';
      this.tmpBcategory = '';
      this.tmpBaddress = '';
    },
    updateSelector() {
      if (this.hasPreviousAddresses) {
        let hasDefault = false;
        for (let i = 0; i < this.bussinessAddresses.length ; i++) {
          if (this.bussinessAddresses[i].is_default) {
            hasDefault = true;
            this.fillSelector(i);
            break;
          }
        }
        if (!hasDefault) {
          this.fillSelector(0);
        }
      } else {
        this.tmpBname = '';
        this.tmpRut = '';
        this.tmpBcategory = '';
        this.tmpBaddress = '';
        this.viewMode = false;
      }
    },
    fillSelector(index) {
      this.tmpBname = this.bussinessAddresses[index].name;
      this.tmpRut = this.bussinessAddresses[index].rut;
      this.tmpBcategory = this.bussinessAddresses[index].business_category;
      this.tmpBaddress = this.bussinessAddresses[index].business_address;
      this.selected = this.bussinessAddresses[index];
    },
    saveNewBAddress() {
      const selected ={
        business_address: this.tmpBaddress,
        business_category: this.tmpBcategory,
        name: this.tmpBname,
        rut: this.tmpRut,
      };
      api.createBillingAddress(selected)
        .then((response) => {
          this.updateUserData();
          this.bussinessAddresses.push(selected);
          this.selected = selected;
          this.viewMode = true;
        })
        .catch((e) => {
          this.nameError = e.response.data.errors;
        });
    }
  },
};
</script>
