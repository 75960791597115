/* eslint no-console: 0 */
/* global document, process */

import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import moment from 'moment';
import TurbolinksAdapter from 'vue-turbolinks';
import VueI18n from 'vue-i18n';
import VueMoment from 'vue-moment';
import Vuex from 'vuex';
import Vue2Filters from 'vue2-filters';
import VeeValidate from 'vee-validate';
import VModal from 'vue-js-modal';
import VCalendar from 'v-calendar';
import vSelect from 'vue-select';
import VueCarousel from 'vue-carousel';
import VueGtm from 'vue-gtm';

import Home from './components/home.vue';
import ContactInformation from './components/profile/contact-information.vue';
import BillingInformation from './components/profile/billing-information.vue';
import Security from './components/profile/security.vue';
import NewOrder from './components/order/new.vue';
import MapForm from './components/map-form.vue';
import ModalPayment from './components/modal-payment.vue';
import OurHistory from './components/our-history.vue';
import SignIn from './components/sign-in.vue';
import SignInOverlay from './components/sign-in-overlay.vue';
import vars from './components/vars';
import store from './components/store';
import Locales from './locales/locales';

const mapConfiguration = {
  installComponents: true,
  load: {
    key: vars.googleMapsKey,
    libraries: 'places',
  },
};

const dictionary = {
  es: {
    messages: {
      'alpha_num': 'Sólo puede usar números y letras.',
      'alpha_spaces': 'Sólo puede usar letras y espacios.',
      email: 'No es un email válido.',
      max: 'No cumple el tamaño máximo establecido.',
      min: 'No cumple el tamaño mínimo establecido.',
      numeric: 'Sólo puede usar números.',
      required: 'Este campo es obligatorio.',
    },
  },
};

const validateConfiguration = {
  locale: 'es',
  dictionary,
};

/* eslint-disable max-statements */
document.addEventListener('turbolinks:load', () => {
  Vue.use(TurbolinksAdapter);
  Vue.use(VueI18n);
  Vue.use(Vuex);
  Vue.use(VueGoogleMaps, mapConfiguration);
  Vue.use(VueMoment, { moment });
  Vue.use(Vue2Filters);
  Vue.use(VeeValidate, validateConfiguration);
  Vue.use(VModal, { dynamic: true });
  Vue.use(vSelect);
  Vue.use(VueCarousel);
  Vue.use(VCalendar, {
    firstDayOfWeek: 2,
    locale: 'es',
    titlePosition: 'center',
    datePickerShowDayPopover: false,
    datePickerTintColor: '#50bfa3',
    themeStyles: {
      wrapper: {
        padding: '10px',
        margin: '30px',
        background: 'white',
        color: '#58585b',
        borderRadius: '6px',
        boxShadow: '0 2px 23px 0 #E4E6E9',
        fontFamily: 'Karla',
      },
      headerTitle: {
        fontSize: '0.9rem',
      },
      dayCellNotInMonth: {
        opacity: 0,
      },
    },
  });

  if (process.env.GTM_CONTAINER_ID) {
    Vue.use(VueGtm, {
      id: process.env.GTM_CONTAINER_ID,
    });
  }

  if (document.getElementById('app')) {
    new Vue({
      el: '#app',
      i18n: new VueI18n({
        locale: document.documentElement.lang,
        messages: Locales.messages,
      }),
      store,
      components: {
        'home': Home,
        'new-order': NewOrder,
        'our-history': OurHistory,
        'sign-in': SignIn,
        'sign-in-overlay': SignInOverlay,
        'contact-information': ContactInformation,
        'billing-information': BillingInformation,
        'security': Security,
        'map-form': MapForm,
        'modal-payment': ModalPayment,
      },
    }).$mount('#app');
  }
});
