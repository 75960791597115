<template>
  <div>
    <div class="formulator__sliders">
      <formulator-slider
        name="Proteína mínima"
        unit="%"
        min="11"
        max="23"
        interval="1"
        v-model="protein"
      />
      <formulator-slider
        name="Energía mínima"
        unit="Mcal"
        min="2.5"
        max="3.4"
        interval="0.1"
        v-model="energy"
      />
      <formulator-slider
        name="FDN máximo"
        unit="%"
        min="-50"
        max="-10"
        interval="1"
        v-model="fdn"
        reverse
      />
      <formulator-slider
        name="CNE mínimo"
        unit="%"
        min="35"
        max="75"
        interval="1"
        v-model="cne"
      />
    </div>
    <hr class="sliders-separator">
    <formulator-salt-selection
      v-model="salt"
    />
    <hr class="sliders-separator">
    <formulator-finishing class="formulator__sliders" />
    <hr class="sliders-separator">
    <formulator-extension
      @update-show="updateShowExtension"
      @update-range-value="updateRangeValue"
    />
    <hr class="sliders-separator">
    <div class="formulator__finish-box">
      <div class="input__button">
        <button
          id="nutrition-formulator-btn"
          class="formulator__button"
          @click="calculate()"
        >
          Calcular
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import evSelect from '../ev-select.vue';
import formulatorSlider from './formulator-slider.vue';
import formulatorFinishing from './formulator-finishing.vue';
import FormulatorExtension from './formulator-extension';
import FormulatorSaltSelection from './formulator-salt-selection';

export default {
  components: {
    evSelect,
    formulatorSlider,
    formulatorFinishing,
    FormulatorExtension,
    FormulatorSaltSelection,
  },
  computed: {
    protein: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.protein;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { protein: value });
      },
    },
    energy: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.energy;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { energy: value });
      },
    },
    fdn: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.fdn;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { fdn: value });
      },
    },
    cne: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.cne;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { cne: value });
      },
    },
    salt: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.salt;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { salt: value });
      },
    },
    ingredientRanges() {
      return this.$store.state.formulator.nutritionFormulator.ingredientRanges;
    },
    ingredientRangesForCheapestRecipe() {
      if (!this.ingredientRanges) return null;

      return Object.entries(this.ingredientRanges).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: this.rangeValueArrayToIngredientRangeObject(value) }),
        {},
      );
    },
    packaging() {
      return this.$store.state.recipe.finishing.packaging.value;
    },
    form() {
      return this.$store.state.recipe.finishing.form.value;
    },
    ingredients() {
      return this.$store.getters.generalIngredients;
    },
    totalSackWeight() {
      return this.$store.state.recipe.finishing.totalSackWeight;
    },
  },
  methods: {
    updateShowExtension(value) {
      this.$store.commit('updateNutritionFormulator', { showExtension: value });
    },
    updateRangeValue({ id, value }) {
      this.$store.commit('updateNutritionFormulator', { ingredientRanges: { ...this.ingredientRanges, [id]: value } });
    },
    rangeValueArrayToIngredientRangeObject(rangeValue) {
      return { min: rangeValue[0], max: rangeValue[1] };
    },
    calculate() {
      this.calculateCheapestRecipe();
    },
    calculateCheapestRecipe() {
      const params = {
        pc: this.protein,
        em: this.energy,
        fdn: this.fdn,
        cne: this.cne,
        salt: this.salt.amount,
        saltId: this.salt.id,
        form: this.form,
        packaging: this.packaging,
        ingredientRanges: this.ingredientRangesForCheapestRecipe,
        totalSackWeight: this.totalSackWeight,
      };

      this.$store.dispatch('cheapestRecipe', params);
      this.$store.commit('updateVia', 'recipe');
    },
  },
  mounted() {
    if (!this.$store.state.firstRecipeLoaded) {
      this.calculateCheapestRecipe();
      this.$store.commit('updateFirstRecipeLoaded', true);
    }
  },
};
</script>
