<template>
  <div id="formulator-ingredients">
    <div class="formulator__sliders">
      <formulator-slider
        v-for="ingredient in ingredients"
        :key="ingredient.name"
        :name="ingredient.name"
        :min="ingredient.min"
        :max="ingredient.max"
        :interval="ingredient.interval"
        :value="ingredientFormulatorIngredients[ingredient.id]"
        @input="(val) => updateValue(ingredient.id, val)"
        unit="%"
        with-input
        is-ingredient
      />
    </div>
    <hr class="sliders-separator">
    <formulator-salt-selection
      :value="ingredientFormulatorSalts[0]"
      @input="(val) => updateSalt(0, val)"
      :ignored-salts="[ingredientFormulatorSalts[1]]"
    />
    <formulator-salt-selection
      v-if="isCoagraWeb"
      :value="ingredientFormulatorSalts[1]"
      @input="(val) => updateSalt(1, val)"
      :ignored-salts="[ingredientFormulatorSalts[0]]"
    />
    <hr class="sliders-separator">
    <formulator-finishing class="formulator__sliders formulator-finishing" />
    <hr class="sliders-separator">
    <div class="formulator__finish-box">
      <div class="input__button">
        <button
          id="ingredients-formulator-btn"
          class="formulator__button"
          :disabled="total != 100"
          @click="getInverseRecipe()"
        >
          Calcular
        </button>
        <div class="ingredients-warning">
          Total: <b><span :class="{ 'ingredients-warning__error': total != 100 }">{{ total }}</span> %</b>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormulatorSlider from './formulator-slider';
import FormulatorFinishing from './formulator-finishing';
import FormulatorSaltSelection from './formulator-salt-selection';

export default {
  components: {
    FormulatorSlider,
    FormulatorFinishing,
    FormulatorSaltSelection,
  },
  computed: {
    ingredients() {
      return this.$store.getters.generalIngredients;
    },
    total() {
      const ingredientSum = Object.entries(this.ingredientFormulatorIngredients).reduce((total, [, percentage]) =>
        total + parseFloat(percentage), 0);
      const saltsSum = this.ingredientFormulatorSalts.reduce((total, salt) =>
        total + parseFloat(salt.amount), 0);

      return (ingredientSum + saltsSum).toFixed(1);
    },
    isCoagraWeb() {
      return this.$store.state.order.isCoagraWeb;
    },
    ingredientFormulatorIngredients() {
      return this.$store.state.formulator.ingredientFormulator.ingredients;
    },
    ingredientFormulatorSalts() {
      return this.$store.state.formulator.ingredientFormulator.salts;
    },
    inverseRecipeIngredients() {
      const query = Object.entries(this.ingredientFormulatorIngredients).map(([id, percentage]) =>
        ({ id, value: percentage }),
      );
      this.ingredientFormulatorSalts.filter(salt => salt.id && salt.amount > 0.0).forEach(salt => {
        (query.push({ id: salt.id, value: salt.amount }));
      });

      return query;
    },
  },
  methods: {
    getInverseRecipe() {
      if (Number(this.total) !== 100) return; // eslint-disable-line no-magic-numbers

      this.$store.dispatch('inverseRecipe', { ingredients: this.inverseRecipeIngredients });
      this.$store.commit('updateVia', 'ingredient');
    },
    updateValue(id, value) {
      this.$store.commit('updateIngredientFormulatorIngredients', { [id]: value });
    },
    updateSalt(index, value) {
      this.$store.commit('setIngredientFormulatorSalt', { index, value });
    },
  },
};
</script>
