<template>
  <div class="formulator__sliders">
    <formulator-slider
      :name="name"
      :unit="unit"
      :min="min"
      :max="max"
      :interval="interval"
      v-model="amount"
      :info-modal="infoModal"
      :disabled="disableSlider"
      :class="{'formulator__sliders--disabled': disableSlider }"
    >
      <template v-slot:title>
        <slot name="title" />
      </template>
    </formulator-slider>
    <div class="select-group">
      <p class="select-group__title">&nbsp;</p>
      <ev-select
        v-model="item"
        :options="items"
      />
    </div>
  </div>
</template>
<script>
import evSelect from '../ev-select.vue';
import formulatorSlider from './formulator-slider.vue';

export default {
  components: {
    evSelect,
    formulatorSlider,
  },
  data() {
    return {
      amount: this.value.amount || 0,
      item: this.items.find(item => item.value === this.value.id) || null,
    };
  },
  props: {
    min: {
      type: [Number, String],
      required: true,
    },
    max: {
      type: [Number, String],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    interval: {
      type: [Number, String],
      required: true,
    },
    infoModal: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    initial: {
      type: Number,
      default: 0,
    },
    updateOnSelect: {
      type: Boolean,
      default: true,
    },
    disableSlider: {
      type: Boolean,
      default: false,
    },
    showCustomAmount: {
      type: Boolean,
      default: false,
    },
    customAmount: {
      type: Number,
      default: 0,
    },
    isIngredient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemValue() {
      const valueObj = {
        amount: this.showCustomAmount ? this.customAmount : this.amount,
        id: this.item ? this.item.value : null,
      };
      if (this.isIngredient) {
        valueObj.ms = this.item ? this.item.ms : 0;
      }

      return valueObj;
    },
    range() {
      return Number(this.max) - Number(this.min);
    },
  },
  methods: {
    updateDefaultValue() {
      this.$store.dispatch('setUpdated', false);
      if (this.item && this.updateOnSelect) {
        // eslint-disable-next-line no-magic-numbers
        this.amount = (this.range * Number(this.item.defaultPercentage) / 100 + Number(this.min)).toFixed(1);
      }
    },
    setInitial() {
      if (!this.item && this.items.length > this.initial) this.item = this.items[this.initial];
    },
  },
  mounted() {
    this.setInitial();
  },
  watch: {
    item() {
      this.updateDefaultValue();
    },
    itemValue(val) {
      this.$emit('input', val);
    },
    items() {
      this.setInitial();
    },
    showCustomAmount(val) {
      if (val) this.amount = this.customAmount;
    },
  },
};
</script>
