<template>
  <div id="order-new__resume">
    <ul class="order-new__list">
      <li>Sub total: <currency :value="subTotal"/></li>
      <li>Despacho: <currency :value="serviceFreightValue"/></li>
      <li>Entrega urgente: <currency :value="serviceDateValue"/></li>
      <li v-if="sackServiceValue > 0">
        Servicios adicionales: <currency :value="sackServiceValue"/>
      </li>
      <li>Servicio de venta ({{ (commission * 100).toFixed(1) }}%): <currency :value="salesService"/></li>
      <li>Total neto: <currency :value="netTotal"/></li>
      <li>IVA: <currency :value="iva"/></li>
      <li><span class="order-new__list-label">Total a pagar</span><currency :value="totalPrice"/></li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import currency from '../../tools/currency';

export default {
  components: {
    currency,
  },
  computed: {
    ...mapGetters([
      'subTotal',
      'serviceDateValue',
      'serviceFreightValue',
      'sackServiceValue',
      'salesService',
      'netTotal',
      'iva',
      'totalPrice',
    ]),
    commission() {
      return this.$store.state.commission;
    },
  },
};
</script>
