<template>
  <modal
    name="register-modal"
    :scrollable="true"
    :max-width="1000"
    width="25%"
    height="auto">
    <div class="register-modal">
      <div class="register-modal-bar">
        <i class="material-icons register-modal-close" v-on:click="$modal.hide('register-modal')">highlight_off</i>
      </div>
      <register-form/>
    </div>
  </modal>
</template>

<script>
import RegisterForm from './register-form.vue';

export default {
  name: 'registerModal',
  components: {
    RegisterForm,
  },
};
</script>
