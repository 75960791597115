<template>
  <div class="order-new__map">
    <div class="order-new__map-form">
      <h3
        v-if="enableCreate"
        class="order-new__map-subtitle"
      >
        Lugar de despacho
      </h3>
      <h2 v-else>
        Lugar de despacho
      </h2>
      <div
        :class="{required: isCreatingOrEditing}"
        class="order-new__map-attribute"
      >
        Nombre
      </div>
      <ev-select
        class="order-new__location-name-dropdown"
        v-model="deliveryAddress"
        label="name"
        :options="deliveryAddresses"
        v-if="!isCreatingOrEditing"
      />
      <input
        class="input order-new__location-name-input"
        v-model="newAddressName"
        placeholder="Ejemplo: Lechería principal"
        name="newAddressName"
        v-else
      >
      <error
        class="order-new__error"
        :err="nameError"
        attribute="name"
      />
      <br>
      <div class="order-new__map-attribute">
        <span :class="{required: isCreatingOrEditing}">Ubicación</span>
        <span
          class="order-new__instruction"
          v-if="isCreatingOrEditing"
        >
          Haz click derecho en la ubicación deseada.
        </span>
      </div>
      <div class="order-new__map-content">
        <gmap-map
          :center="center"
          :zoom="10"
          :map-type-id="'hybrid'"
          @rightclick="changeMarker($event)"
          class="order-new__google-map"
        >
          <gmap-marker
            :position="marker.position"
            :clickable="isCreatingOrEditing"
            :draggable="isCreatingOrEditing"
            @click="center=marker.position"
            @dragend="updatePosition(marker, $event.latLng)"
          />
        </gmap-map>
        <br>
        <div class="order-new__map-attribute">
          <span :class="{required: isCreatingOrEditing}">Referencia</span>
          <span
            class="order-new__instruction"
            v-if="isCreatingOrEditing"
          >
            ¿Cómo llegamos hasta ahí?
          </span>
        </div>
        <div
          v-if="!isCreatingOrEditing"
          class="order-new__map-attribute-value"
        >
          {{ referenceStore }}
        </div>
        <textarea
          v-else
          class="input reference-textarea"
          v-model="referenceStore"
          placeholder="Ejemplo: por ruta 5, tomar el cruce Trafún y avanzar 0,7kms. Entrar por portón metálico a mano izquierda."
        />
      </div>
    </div>
    <div class="order-new__map-buttons-container">
      <template v-if="enableCreate">
        <div class="order-new__map-info-container">
          <div
            class="info"
            v-if="createAddressMode"
          >
            Los <label class="required" /> Señalan los campos obligatorios.
          </div>
        </div>
        <button
          @click="enterCreateAddressMode"
          class="btn order-new__new-location-button"
          v-if="!createAddressMode"
        >
          Agregar Ubicación
        </button>
        <button
          @click="createDeliveryAddress"
          class="btn"
          :disabled="!newAddressName || !referenceStore"
          v-else
        >
          Guardar Ubicación
        </button>
        <button
          @click="exitCreateAddressMode"
          class="btn btn-outline"
          v-if="canCancelCreation"
        >
          Cancelar
        </button>
      </template>
      <template v-else-if="enableDeleteAndEdit">
        <div class="c-table-info__map-edit-btn-container">
          <button
            @click="enterEditAddressMode"
            class="btn c-table-info__edit-btn"
            v-if="!editAddressMode"
          >
            Editar
          </button>
          <button
            @click="editDeliveryAddress"
            class="btn"
            :disabled="!newAddressName || !referenceStore"
            v-if="editAddressMode"
          >
            Guardar
          </button>
          <button
            @click="exitEditAddressMode"
            class="btn btn-outline"
            v-if="editAddressMode"
          >
            Cancelar
          </button>
        </div>
        <button
          @click="deleteDeliveryAddress"
          class="btn c-table-info__edit-btn c-table-info__edit-btn"
        >
          Eliminar
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import VueNumeric from 'vue-numeric';
import api from './store/api';
import evSelect from './ev-select.vue';
import error from '../tools/error';
import vars from './vars';

export default {
  components: {
    VueNumeric,
    evSelect,
    error,
  },
  props: {
    addresses: Array,
    enableCreate: Boolean,
    enableDeleteAndEdit: Boolean,
  },
  data() {
    return {
      center: { lat: vars.alisurCoords.lat, lng: vars.alisurCoords.lng },
      deliveryAddress: this.addresses.filter(address => address.is_default === true)[0] ? this.addresses.filter(address => address.is_default === true)[0] : this.addresses[0],
      createAddressMode: this.addresses.length === 0 && this.enableCreate,
      editAddressMode: false,
      newAddressName: '',
      nameError: {},
      deliveryAddresses: this.addresses,
    };
  },
  beforeMount() {
    if (this.deliveryAddress) {
      this.updateAddress(this.deliveryAddress);
    } else {
      this.setEmptyDeliveryAddresses();
    }
  },
  computed: {
    hasNoDeliveryAddresses() {
      return this.deliveryAddresses.length === 0 ||
        (this.deliveryAddresses.length === 1 && !this.deliveryAddresses[0].id);
    },
    isCreatingOrEditing() {
      return this.createAddressMode || this.editAddressMode;
    },
    googleMapUrl() {
      return this.$store.getters.googleMapUrl;
    },
    marker: {
      get() {
        return this.$store.state.order.address.marker;
      },
      set(value) {
        this.$store.commit('updateContactUserAddressMarker', value);
      },
    },
    referenceStore: {
      get() {
        return this.$store.state.order.address.reference;
      },
      set(value) {
        this.$store.commit('updateContactUserAddressReference', value);
      },
    },
    canCancelCreation() {
      return this.createAddressMode && this.deliveryAddresses.length > 0;
    },
  },
  methods: {
    setEmptyDeliveryAddresses() {
      this.deliveryAddresses.push({
        name: 'Ejemplo: Lechería principal',
        lat: vars.alisurCoords.lat,
        lng: vars.alisurCoords.lng,
        reference: '',
      });
      this.deliveryAddress = { ...this.deliveryAddresses[0] };
    },
    removeEmptyDeliveryAddresses() {
      if (!this.deliveryAddresses[0].id) {
        this.editAddressMode = false;
        this.deliveryAddresses = [];
      }
    },
    updatePosition(marker, _position) {
      const position = { lat: _position.lat(), lng: _position.lng() };
      this.$store.commit('updateContactUserAddressMarker', position);
      this.$store.dispatch('updateFreightService');
    },
    changeMarker(position) {
      if (this.isCreatingOrEditing) {
        this.updatePosition(null, position.latLng);
      }
    },
    updateAddress(value) {
      const parsedLat = parseFloat(value.lat);
      const parsedLng = parseFloat(value.lng);
      const position = { lat: parsedLat, lng: parsedLng };
      this.center.lat = parsedLat;
      this.center.lng = parsedLng;
      this.$store.commit('updateContactUserAddressMarker', position);
      this.$store.dispatch('updateFreightService');
      this.$store.commit('updateContactUserAddressReference', value.reference);
      this.$store.commit('updateContactUserAddressName', value.name);
    },
    createDeliveryAddress() {
      const address = {
        name: this.newAddressName,
        lat: this.marker.position.lat,
        lng: this.marker.position.lng,
        reference: this.referenceStore,
      };

      return api.createDeliveryAddress(address)
        .then((response) => {
          this.removeEmptyDeliveryAddresses();
          this.deliveryAddresses.push(response.data.address);
          this.deliveryAddress = response.data.address;
          this.createAddressMode = false;
          this.nameError = {};
          this.newAddressName = '';
        })
        .catch((e) => {
          this.nameError = e.response.data.errors;
        });
    },
    enterCreateAddressMode() {
      this.createAddressMode = true;
      this.updateAddress({
        lat: vars.alisurCoords.lat,
        lng: vars.alisurCoords.lng,
        reference: '',
      });
    },
    exitCreateAddressMode() {
      this.createAddressMode = false;
      this.nameError = {};
      this.newAddressName = '';
      this.updateAddress(this.deliveryAddress);
    },
    editDeliveryAddress() {
      const that = this;

      if (this.hasNoDeliveryAddresses) {
        this.createDeliveryAddress();
      } else {
        const address = {
          id: this.deliveryAddress.id,
          name: this.newAddressName,
          lat: this.marker.position.lat,
          lng: this.marker.position.lng,
          reference: this.referenceStore,
        };
        api.editDeliveryAddress(address)
          .then((response) => {
            const targetAddressIndex = that.deliveryAddresses.findIndex(
              (dAddress) => dAddress.id === that.deliveryAddress.id);
            that.deliveryAddress = response.data.address;
            that.$set(that.deliveryAddresses, targetAddressIndex, that.deliveryAddress);
            that.editAddressMode = false;
            that.nameError = {};
          })
          .catch((e) => {
            that.nameError = e.response.data.errors;
          });
      }
    },
    deleteSelectedDeliveryAddress() {
      this.deliveryAddresses = this.deliveryAddresses.filter(
        (dAddress) => dAddress.id !== this.deliveryAddress.id);
      if (this.deliveryAddresses.length === 0) {
        this.setEmptyDeliveryAddresses();
      }
      this.deliveryAddress = this.deliveryAddresses[0];
    },
    deleteDeliveryAddress() {
      const that = this;

      const address = {
        id: this.deliveryAddress.id,
      };
      api.deleteDeliveryAddress(address)
        .then(() => {
          that.deleteSelectedDeliveryAddress(address);
        });
    },
    enterEditAddressMode() {
      this.editAddressMode = true;
      this.newAddressName = this.deliveryAddress.name;
    },
    exitEditAddressMode() {
      this.editAddressMode = false;
      this.nameError = {};
      this.updateAddress(this.deliveryAddress);
    },
  },
  watch: {
    deliveryAddress(value) {
      this.updateAddress(value);
    },
  },
};
</script>
