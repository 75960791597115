export default {
  slider: {
    background: '#ccc',
    text: '#1c1c1c',
    primary: '#fe5001',
    secondary: '#ff905d',
    piecewiseActive: '#3498db',
  },
  googleMapsKey: 'AIzaSyCW0qP0m67qMd9ms9KQ4Q68QwuQYDxynuc',
  alisurCoords: { lat: -40.484364, lng: -73.029934 },
};
