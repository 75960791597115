import Vue from 'vue';

export default {
  state: {
    selectedTab: null,
    dairyInfo: {
      milk: 26,
      weight: 550,
      protein: 3.6,
      fat: 3.8,
      meadow: {},
      forage: [],
    },
    nutritionFormulator: {
      protein: 16,
      energy: 3,
      fdn: 40,
      cne: 45,
      salt: { amount: 0, id: null },
      ingredientRanges: {},
      showExtension: false,
    },
    ingredientFormulator: {
      ingredients: {},
      salts: [
        { amount: 0, id: null },
        { amount: 0, id: null },
      ],
    },
  },
  mutations: {
    updateDairyInfo: (state, payload) => {
      state.dairyInfo[payload.key] = payload.value;
    },
    setSelectedTab: (state, payload) => {
      state.selectedTab = payload;
    },
    updateNutritionFormulator: (state, payload) => {
      state.nutritionFormulator = { ...state.nutritionFormulator, ...payload };
    },
    updateIngredientFormulatorIngredients: (state, payload) => {
      state.ingredientFormulator.ingredients = { ...state.ingredientFormulator.ingredients, ...payload };
    },
    setIngredientFormulatorSalt: (state, payload) => {
      Vue.set(state.ingredientFormulator.salts, payload.index, payload.value);
    },
  },
  actions: {
    setIngredientRanges: ({ commit, state, getters }) => {
      const ranges = getters.generalIngredients.reduce(
        (acc, ingredient) => ({ ...acc, [ingredient.id]: ingredient.rangeValue }), {},
      );
      commit(
        'updateNutritionFormulator',
        { ingredientRanges: { ...ranges, ...state.nutritionFormulator.ingredientRanges } },
      );
    },
    setIngredientFormulatorValues({ commit, state, getters }) {
      const values = getters.generalIngredients.reduce(
        (acc, ingredient) => ({ ...acc, [ingredient.id]: ingredient.value }), {},
      );
      commit(
        'updateIngredientFormulatorIngredients',
        { ...values, ...state.ingredientFormulator.ingredients },
      );
    },
  },
};
