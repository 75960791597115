<template>
  <p @click="openHistoryModal" class="footer__link">Nuestra historia</p>
</template>

<script>
export default {
  methods: {
    openHistoryModal() {
      this.$modal.show('history-modal');
    },
  },
};
</script>
