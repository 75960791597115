<template>
  <div class="output-info">
    <div class="output-info__row">
        <div class="output-info__row-element--left output-price--left">
          Valor neto
        </div>
        <div class="output-info__row-element--right output-price--right">
          <currency :value="price"></currency> <span class="output-price__label">x kg</span>
        </div>
      </div>
      <div class="output-info__row">
        <div class="output-info__row-element--left">
          Peso total
        </div>
        <div class="output-info__row-element--right">
          <vue-numeric separator="." v-bind:read-only="true" :value="weight"></vue-numeric> kg
        </div>
      </div>
      <div class="output-info__row">
        <div class="output-info__row-element--left">
          Sub total
        </div>
        <div class="output-info__row-element--right">
          <currency :value="purchaseValue"></currency>
        </div>
      </div>
  </div>
</template>
<script>
import VueNumeric from 'vue-numeric';
import currency from '../../../tools/currency';

export default {
  data() {
    return {};
  },
  components: {
    VueNumeric,
    currency,
  },
  computed: {
    purchaseValue() {
      return this.price * this.weight;
    },
    price() {
      return this.$store.state.order.isCoagraWeb ? 0 : this.$store.state.recipe.price;
    },
    weight() {
      return this.$store.state.recipe.weight;
    },
  },
};
</script>
