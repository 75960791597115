<template>
  <modal name="history-modal"
         :scrollable="true"
         :max-width="1000"
         width="70%"
         height="auto">
    <div class="history-modal">
      <div class="history-modal-bar">
        <i class="material-icons history-modal-close" v-on:click="$modal.hide('history-modal');">highlight_off</i>
      </div>
      <div class="history-modal-content">
        <div class="history-modal-image-content">
          <img class="history-modal-image" :src="image" alt="">
        </div>
        <div class="history-modal-info">
          <div class="history-modal-header">
            <h3>Nuestra historia</h3>
          </div>
          <div class="history-modal-body">
            <p>
              Alisur está conformado por 29 socios vinculados a la actividad agrícola de la X región. En conjunto administran cerca de 15.000 hectáreas en las cuales se siembra gran parte de la materia prima que posteriormente Alisur procesa y comercializa en la industria salmonera y lechera del sur de Chile.
            </p>
            <p>
              La primera inversión fue realizada el año 2008 y consistió en silos de acopio de granos, la instalación de equipos para la recepción de materias primas y el montaje de una planta de extracción de aceite de raps. Esta última tenía como objetivo atender el creciente interés de la industria salmonera por fuentes renovables de proteína (Torta de raps) y de ácidos grasos (Aceite de raps), a la vez que dotaba de un poder de compra en la zona, de un cultivo estratégico en la rotación de siembras. Los principales clientes de ésta línea de productos son Nutreco y Biomar.
            </p>
            <p>
              El año 2014 Alisur invierte en una planta de alimentos balanceados de procedencia holandesa, de última tecnología y con una capacidad de 60.000 toneladas al año, respondiendo a la necesidad de acercar la fabricación de alimentos a su lugar de consumo. Esta inversión se hizo pensando en poder ofrecer a sus clientes de un estándar superior de servicio, que se reflejara en el uso de materias primas de calidad, bien almacenadas, procesadas de manera eficiente y con entregas seguras y puntuales. Dentro de los clientes más importantes en esta área se encuentran las empresas Colun y Coagra.
            </p>
            <p>
              El año 2018 Alisur desarrolla la primera plataforma online de nutrición animal, que busca hacer uso de herramientas digitales, para darles control y transparencia a los productores lecheros, sobre el contenido nutricional y de ingredientes que componen su alimento. Esta plataforma a su vez incorpora todas las ventajas del e-commerce, como el acceso al historial de compras, a la personalizacion de la experiencia de compra y a la planificación de los despachos, funciones que están siempre disponibles y accesibles desde cualquier lugar.
            </p>
            <p>
              Equipo
            </p>

            <p>
              Presidente: Ricardo Montesinos<br>
              Vicepresidente: Mauricio Magnasco<br>
              Director: Alejandro Montesinos<br>
              Director: Rodrigo Bebin<br>
              Director: Eduardo Von Conta<br>
            </p>
            <p>
              Gerente General: Mauricio Magnasco<br>
              Gerente Comercial: Marcelo Gutierrez<br>
            </p>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  name: 'historyModal',
  props: ['image'],
  data() {
    return {
    };
  },
};
</script>
