<template>
  <div class="new-order__details">
    <table class="g-table">
      <tr>
        <td class="g-table__biggest-cell">
          Sub total
        </td>
        <td><currency :value="subTotal" /></td>
      </tr>
      <tr>
        <td>Despacho</td>
        <td><currency :value="serviceFreightValue" /></td>
      </tr>
      <tr>
        <td>Entrega urgente</td>
        <td><currency :value="serviceDateValue" /></td>
      </tr>
      <tr v-if="sackServiceValue > 0">
        <td>Servicios adicionales</td>
        <td><currency :value="sackServiceValue" /></td>
      </tr>
      <tr>
        <td>Servicio de venta ({{ (finalCommission * 100).toFixed(1) }}%)</td>
        <td><currency :value="salesService" /></td>
      </tr>
      <tr>
        <td>Total neto</td>
        <td><currency :value="netTotal" /></td>
      </tr>
      <tr>
        <td>IVA (19%)</td>
        <td><currency :value="iva" /></td>
      </tr>
      <tr>
        <th class="g-table__important-cell">
          Monto a pagar
        </th>
        <th class="g-table__important-cell">
          <currency :value="totalPrice" />
        </th>
      </tr>
    </table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import currency from '../../tools/currency';

export default {
  components: {
    currency,
  },
  computed: {
    ...mapGetters([
      'subTotal',
      'serviceDateValue',
      'serviceFreightValue',
      'sackServiceValue',
      'salesService',
      'netTotal',
      'iva',
      'totalPrice',
      'finalCommission',
    ]),
  },
};
</script>
