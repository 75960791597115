<template>
  <div class="ingredient-content">
    <div class="ingredient-content__title">
      Objetivos
    </div>
    <div class="ingredient-content__table">
      <div
        v-for="(goal, key) in goalsInfo"
        :key="key"
        class="ingredient-content__row"
      >
        <div class="ingredient-content__column">
          {{ goal.label }}
        </div>
        <div class="ingredient-content__column">
          {{ showGoals ? `${goals[key]} ${goal.measure}` : '-' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      goalsInfo: {
        expectedMilk: { label: 'Leche esperada', measure: 'l/día' },
        producedMilkEm: { label: 'Leche potencial EM', measure: 'l/día' },
        producedMilkPm: { label: 'Leche potencial PM', measure: 'l/día' },
        expectedDryMatter: { label: 'Materia seca requerida', measure: 'kg' },
        givenDryMatter: { label: 'Materia seca entregada', measure: 'kg' },
        carbonEmission: { label: 'Emisión de CO2 eq.', measure: 'g/l leche' },
      },
    };
  },
  computed: {
    goals() {
      return this.$store.state.highPrecisionGoals;
    },
    showGoals() {
      return this.$store.state.showGoals;
    },
  },
};
</script>
