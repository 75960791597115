<template>
  <div class="c-table-info">
    <h2>{{ $t('user.securityTitle') }}</h2>
    <div class="c-table-info__row">
      <div class="c-table-info__cell">
        <div class="c-table-info__title">{{ $t('user.security.currentPassword') }}</div>
        <input :disabled="!editPasswordMode" class="input" v-model="passwords.currentPassword" name="currentPassword" type="password">
        <error :err="passwordErrors" attribute="current_password"></error>
      </div>
      <div class="c-table-info__cell"></div>
      <div class="c-table-info__cell c-table-info__cell--centered">
        <button @click="enterEditPasswordMode" class="btn c-table-info__edit-btn" v-if="!editPasswordMode">Editar</button>
        <template v-else>
          <button :disabled="hasEmptyInput" @click="editPassword" class="btn">Guardar</button>
          <button @click="cancelEditPasswordMode" class="btn btn-outline">Cancelar</button>
        </template>
      </div>
    </div>
    <div class="c-table-info__row">
      <div class="c-table-info__cell" v-if="editPasswordMode">
        <div class="c-table-info__title">{{ $t('user.security.password') }}</div>
        <input class="input" v-model="passwords.password" name="password" type="password">
        <error :err="passwordErrors" attribute="password"></error>
      </div>
      <div class="c-table-info__cell" v-if="editPasswordMode">
        <div class="c-table-info__title">{{ $t('user.security.passwordConfirmation') }}</div>
        <input class="input" v-model="passwords.passwordConfirmation" name="passwordConfirmation" type="password">
        <error :err="passwordErrors" attribute="password_confirmation"></error>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import humps from 'humps';
import error from '../../tools/error';

export default {
  components: {
    error,
  },
  data() {
    return {
      editPasswordMode: false,
      passwords: {
        currentPassword: 'a_password',
        password: '',
        passwordConfirmation: '',
      },
      passwordErrors: {},
    };
  },
  computed: {
    hasEmptyInput() {
      return !(this.passwords.currentPassword &&
        this.passwords.password &&
        this.passwords.passwordConfirmation);
    },
  },
  methods: {
    resetPasswords() {
      this.passwords.currentPassword = 'a_password';
      this.passwords.password = '';
      this.passwords.passwordConfirmation = '';
    },
    resetPasswordErrors() {
      this.passwordErrors = {};
    },
    editPassword() {
      const that = this;

      axios.put('/api/v1/profile/update_password', {
        user: humps.decamelizeKeys(this.passwords),
      }, {
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      }).then(() => {
        that.cancelEditPasswordMode();
      }).catch((e) => {
        that.passwordErrors = e.response.data.errors;
      });
    },
    enterEditPasswordMode() {
      this.passwords.currentPassword = '';
      this.editPasswordMode = true;
    },
    cancelEditPasswordMode() {
      this.resetPasswords();
      this.resetPasswordErrors();
      this.editPasswordMode = false;
    },
  },
};
</script>
