<template>
  <div>
    <modal-invalid-ingredients />
    <modal-unavailable-ingredients />
    <div class="order-new">
      <div
        v-if="step === 'form'"
        class="order-new__form-content"
      >
        <div class="order-new__form">
          <div class="order-new__user-info">
            <user-info-form
              :email="email"
              :name="name"
              :surname="surname"
              :phone1="phone1"
              :phone2="phone2"
              :baddresses="baddresses"
            />
          </div>
          <map-form
            :addresses="deliveryAddresses"
            enable-create
          />
          <div class="order-new__formula-name">
            <h3 class="order-new__subtitle">
              Nombre de la fórmula
            </h3>
            <input
              class="input order-new__single-input"
              :value="formulaName"
              @input="updateFormulaName"
              placeholder="Asignale un nombre a tu fórmula (opcional)"
              name="formulaName"
            >
          </div>
          <div class="order-new__calendar">
            <h3 class="order-new__subtitle">
              Selecciona la fecha estimada de entrega
            </h3>
            <date-form />
            <div class="order-new__calendar-disclaimer">
              Los pedidos urgentes tendrán un cobro extra
            </div>
          </div>
        </div>
        <div class="order-new__resume">
          <h2 class="order-new__resume-title">
            Valor a pagar
          </h2>
          <span class="order-new__resume-payment-subtitle">
            Valor neto <currency :value="netValue" /> x kg
          </span>
          <div class="order-new__resume-items">
            <amount-payable class="order-new__resume-details" />
            <div class="order-new__button">
              <div
                class="info"
                v-if="!isValidOrder"
              >
                Para continuar tienes que haber <br>
                ingresado toda la información requerida
              </div>
              <button
                @click="goToConfirm"
                class="btn order-new__button-confirm"
                :disabled="!isValidOrder"
              >
                Confirmar orden
              </button>
              <a
                href="/"
                class="order-new__back"
                data-turbolinks="false"
              >
                Página anterior
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="order-new__confirmation-content"
      >
        <confirmation
          :email="email"
          @back="goToForm"
        />
      </div>
    </div>
  </div>
</template>
<script>
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import qs from 'qs';
import evSelect from '../ev-select.vue';
import amountPayable from './amount-payable.vue';
import userInfoForm from './user-info-form.vue';
import mapForm from '../map-form.vue';
import dateForm from './date-form.vue';
import confirmation from './confirmation.vue';
import modalInvalidIngredients from '../modal-invalid-ingredients';
import modalUnavailableIngredients from '../modal-unavailable-ingredients';
import currency from '../../tools/currency';

const PERCENTAGE_TOLERANCE = 2;

export default {
  components: {
    amountPayable,
    userInfoForm,
    mapForm,
    dateForm,
    confirmation,
    modalInvalidIngredients,
    modalUnavailableIngredients,
    evSelect,
    currency,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    surname: {
      type: String,
      default: '',
    },
    phone1: {
      type: String,
      default: '',
    },
    phone2: {
      type: String,
      default: '',
    },
    credit: {
      type: String,
      default: '',
    },
    baddresses: {
      type: Array,
      default: () => [],
    },
    deliveryAddresses: {
      type: Array,
      default: () => [],
    },
    ingredientsProp: {
      type: Array,
      default: () => [],
    },
    sackServicePrice: {
      type: String,
      default: '0',
    },
    commission: {
      type: String,
      default: '0.05',
    },
    coagraFormulator: {
      type: Boolean,
      default: false,
    },
    inverseRecipe: {
      type: Object,
      default: undefined,
    },
    product: {
      type: Object,
      default: undefined,
    },
    dateAvailabilityAndFees: {
      type: Object,
      required: true,
    },
    form: {
      type: String,
      default: undefined,
    },
    packaging: {
      type: String,
      default: undefined,
    },
    via: {
      type: String,
      default: undefined,
    },
    customName: {
      type: String,
      default: undefined,
    },
    totalSackWeight: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      sackService: { value: false, label: 'No' },
      step: 'form',
    };
  },
  beforeMount() {
    this.$store.dispatch('updateContactUserInfo', { creditAllowed: this.credit === 'true' });
    this.$store.dispatch('updateSackServiceBasePrice', this.sackServicePrice);
    this.$store.dispatch('updateIsCoagraWeb', { isCoagraWeb: this.coagraFormulator });
    this.$store.dispatch('setDateAvailabilityAndFees', this.dateAvailabilityAndFees);
    this.$store.commit('updateCommission', this.commission);
    this.saveProductOrFormulatorOrderParams();
  },
  methods: {
    saveProductOrFormulatorOrderParams() {
      if (this.product) {
        this.$store.dispatch('setProductDetails', this.product);
      } else {
        this.setOrderParams();
      }
    },
    setOrderParams() {
      const form = this.$store.getters.allTranslatedForms.find(e => e.value === this.form);
      const packaging = this.$store.getters.extendedPackagingList.find(e => (
        e.value === this.packaging && (!this.totalSackWeight || e.weight === parseInt(this.totalSackWeight, 10))
      ));

      this.$store.dispatch('updateForm', form);
      this.$store.commit('updatePackaging', packaging);
      this.$store.commit('updateVia', this.via);
      this.$store.dispatch('setIngredients', this.ingredientsProp);
      if (this.customName) this.$store.dispatch('updateFormulaName', this.customName);
      if (this.totalSackWeight) this.$store.commit('updateTotalSackWeight', this.totalSackWeight);
      this.validateAndSaveRecipe();
    },
    goToConfirm() {
      this.step = 'confirm';
    },
    goToForm() {
      this.step = 'form';
    },
    urlIngredientPercentagesWithinRange(urlIngredients) {
      if (this.$store.state.order.isCoagraWeb) {
        return true;
      }
      const ingredients = keyBy(values(this.ingredients), ({ id }) => id);
      let ingredientsValidated = true;
      urlIngredients.forEach((urlIngredient) => {
        const ingredientPercentage = Number(urlIngredient.value);
        const maxPercentage = Number(ingredients[urlIngredient.id].max) + PERCENTAGE_TOLERANCE;
        const minPercentage = Number(ingredients[urlIngredient.id].min) - PERCENTAGE_TOLERANCE;
        if (ingredientPercentage > maxPercentage || ingredientPercentage < minPercentage) {
          ingredientsValidated = false;
        }
      });

      return ingredientsValidated;
    },
    validateAndSaveRecipe() {
      const queryObject = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const queryIngredientsArray = Object.entries(queryObject.ingredients_proportion).map((([id, proportion]) =>
        ({ id, value: proportion })
      ));
      if (!this.allUrlIngredientsAreFormulatorIngredients(queryIngredientsArray)) {
        this.$modal.show('unavailable-ingredients-modal');
      } else if (this.urlIngredientPercentagesWithinRange(queryIngredientsArray)) {
        this.$store.dispatch('saveFormulaRecipe', this.inverseRecipe.details);
      } else {
        this.$modal.show('invalid-ingredients-modal');
      }
    },
    resetScroll() {
      window.scrollTo(0, 0);
    },
    updateFormulaName(e) {
      this.$store.dispatch('updateFormulaName', e.target.value);
    },
    updateSackServicePrice(e) {
      if (e.value) {
        const sackServicePrice = this.$store.getters.sackServiceBasePrice * this.$store.state.recipe.weight;

        this.$store.dispatch('updateSackDischargeService', sackServicePrice);
      } else {
        this.$store.dispatch('updateSackDischargeService', 0);
      }
    },
    allUrlIngredientsAreFormulatorIngredients(urlIngredients) {
      return urlIngredients
        .map(ingredient => Number(ingredient.id))
        .filter(id => !isNaN(id))
        .every((urlIngredientId) => this.formulatorIngredientIds.includes(urlIngredientId));
    },
  },
  computed: {
    isValidOrder() {
      return this.$store.getters.isValidOrder;
    },
    ingredients() {
      return this.$store.state.ingredients;
    },
    formulatorIngredientIds() {
      return this.$store.getters.formulatorIngredients.map(({ id }) => id);
    },
    formulaName() {
      return this.$store.state.order.formulaName;
    },
    finishingType() {
      return this.$store.getters.orderItemFinishingType;
    },
    isBagged25() {
      return this.finishingType.packaging.value === 'bagged25';
    },
    orderWeight() {
      return this.$store.state.recipe.weight;
    },
    netValue() {
      return this.$store.getters.netTotal / this.orderWeight;
    },
  },
  watch: {
    step: 'resetScroll',
  },
};
</script>
