<template>
  <span class="nutritional-info">
    <vue-numeric :precision="0" :read-only="true" :value="value" v-if="property !== 'em'"></vue-numeric>
    <vue-numeric :precision="1" :read-only="true" :value="Math.round(value*10)/10" v-else separator="."></vue-numeric>
    <span v-if="property !== 'em'">%</span>
    <span v-else>Mcal</span>
  </span>
</template>
<script>
  import VueNumeric from 'vue-numeric';

  export default {
    components: {
      VueNumeric,
    },
    props: ['property', 'value'],
  }
</script>
