<template>
  <div class="carousel-card">
    <h3 :class=" product.name.length < 15 ? 'carousel-card__short-name' : '' ">
      {{ product.name }}
    </h3>
    <h2>
      <vue-numeric
        currency="$"
        separator="."
        :value="product.kilogram_price"
        :precision="0"
        :read-only="true"
      />
      x kg
    </h2>
    <div class="label label-price">
      Sub total:
      <vue-numeric
        currency="$"
        :precision="0"
        separator="."
        :read-only="true"
        :value="product.kilogram_price * product.unit_tons * 1000"
      />
    </div>
    <div class="carousel-card__address">
      <div class="pin-icon" />
      <span v-if="product.address">Puesto: {{ product.address }}</span>
      <span v-else>Puesto no especificado</span>
    </div>
    <div class="carousel-card__tags">
      <span
        class="carousel-card__tag"
        v-if="product.form"
      >{{ translate(product.form) }}</span>
      <span
        class="carousel-card__tag"
        v-if="product.packaging"
      >{{ translate(product.packaging) }}</span>
    </div>
    <div class="label label-weight">
      {{ product.unit_tons }} TONS
    </div>
    <div class="btns">
      <div
        class="btn btn-outline"
        @click="show()"
      >
        Ficha
      </div>
      <button
        class="btn"
        @click="checkIfAuth"
      >
        Continuar
      </button>
    </div>
  </div>
</template>

<script>
import VueNumeric from 'vue-numeric';
import helpers from './helpers';

export default {
  components: {
    VueNumeric,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    checkIfAuth() {
      if (this.loggedIn) {
        this.goToNewOrder();
      } else {
        this.$store.state.nextPath = `/order/new?product=${this.product.id}`;
        this.showAuthModal();
      }
    },
    showAuthModal() {
      this.$modal.show('auth-modal');
    },
    goToNewOrder() {
      window.location.href = `/order/new?product=${this.product.id}`;
    },
    show() {
      this.$modal.show('conditional-modal', { product: this.product });
    },
    hide() {
      this.$modal.hide('conditional-modal');
    },
    translate(word) {
      return helpers.translate(word);
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    },
  },
};
</script>
