<template>
  <div class="register-form">
    <div class="register-form__success" v-if="success">
      Hemos recibido tu solicitud. En las próximas 24 horas un ejecutivo de Alisur se contactará contigo.
    </div>
    <h3
      class="register-form__header"
      v-if="!mix"
    >
      Quiero saber más...
    </h3>
    <p class="register-form__info" :class="{ 'register-form__info--mix': mix }">
      Completa el formulario y nuestro asesor nutricional te contactará para resolver todas tus dudas. Si
      quieres trabajar con nosotros, partiremos con una completa asesoría en terreno sin costo. Infórmate
      además de nuestros otros beneficios:
    </p>
    <ul class="register-form__info">
      <li>Descuentos y financiamiento</li>
      <li>Sales minerales personalizadas</li>
      <li>Despachos en 48 horas</li>
    </ul>
    <div class="register-form__label-input">
      <label>Nombre </label><br/>
      <input class="register-form__input" autofocus v-model="name" name="user[name]"><br/>
      <error :err="registrationErrors" attribute="name"></error>
    </div>
    <div class="register-form__label-input">
      <label>Apellido </label><br/>
      <input class="register-form__input" v-model="surname" name="user[surname]"><br/>
      <error :err="registrationErrors" attribute="surname"></error>
    </div>
    <div class="register-form__label-input">
      <label>Correo </label><br/>
      <input class="register-form__input" type="email" v-model="email" name="user[email]"><br/>
      <error :err="registrationErrors" attribute="email"></error>
    </div>
    <div class="register-form__label-input">
      <label>Teléfono </label><br/>
      <input class="register-form__input" type="tel" v-model="phone" name="user[phone]"><br/>
      <error :err="registrationErrors" attribute="phone"></error>
    </div>
    <div class="register-form__label-input">
      <label>Información adicional </label><br/>
      <textarea class="register-form__input" v-model="info" name="user[info]" rows="4" placeholder="Agrega cualquier dato que nos facilite contactarnos contigo. (Opcional)"></textarea><br/>
      <error :err="registrationErrors" attribute="info"></error>
    </div>
    <div class="register-form__submit">
      <button type="submit" class="btn btn--wide" @click="signUp" :disabled="loading">
        <span v-if="loading">Enviando...</span>
        <span v-else>Enviar</span>
      </button><br/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import humps from 'humps';
import error from '../tools/error';

export default {
  name: 'registerForm',
  components: {
    error,
  },
  props: {
    mix: Boolean,
  },
  data() {
    return {
      loading: false,
      success: false,
      name: '',
      surname: '',
      email: '',
      phone: '',
      info: '',
      registrationErrors: {},
    };
  },
  methods: {
    signUp() {
      this.loading = true;

      const params = {
        name: this.name,
        surname: this.surname,
        email: this.email,
        phone: this.phone,
        additionalInfo: this.info,
      };
      const that = this;

      return axios.post('/api/v1/invitation_requests', humps.decamelizeKeys({
        invitationRequest: params,
      }), {
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      }).then(() => {
        this.loading = false;
        this.success = true;
      }).catch(e => {
        this.loading = false;
        that.registrationErrors = e.response.data.errors;
      });
    },
  },
};
</script>
