<template>
  <div class="home-welcome">
    <div class="home-welcome__carousel">
      <carousel
        :per-page="1"
        :mouse-drag="false"
        :autoplay="true"
        :autoplay-timeout="6000"
        :loop="true"
        :autoplay-hover-pause="false"
        :pagination-position="'top-overlay'"
        :center-mode="true"
      >
        <template
          v-for="(imagePath, key) in imagesPaths()"
        >
          <slide
            v-if="imagePath !== ''"
            :key="key"
            class="home-welcome__slide"
          >
            <img :src="imagePath">
          </slide>
        </template>
      </carousel>
    </div>
    <div class="home-welcome__content">
      <div class="home-welcome__signup-button-container">
        <div
          v-if="!loggedIn"
          @click="showRegisterModal"
          class="home-welcome__signup-button"
        >
          Quiero saber más...
        </div>
        <div
          v-else
          class="home-welcome__signup-button home-welcome__signup-button--inactive"
        >
          ¡Hola {{ username }}!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'HomeWelcome',
  components: {
    Carousel,
    Slide,
  },
  props: {
    username: {
      type: String,
      default: '',
    },
    images: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
  },
  methods: {
    showRegisterModal() {
      this.$modal.show('register-modal');
    },
    imagesPaths() {
      const urls = JSON.parse(this.images);

      return urls;
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    },
  },
};
</script>
