<template>
  <div>
    <div class="user-info__table">
      <div class="user-info__table-row">
        <div class="user-info__table-data">Referencia</div>
      </div>
      <div class="user-info__table-row">
        <div class="user-info__table-data">{{ reference }}</div>
      </div>
    </div>
    <gmap-map
      :center="center"
      :zoom="15"
      :map-type-id="'hybrid'"
      style="width: 100%; height: 400px;"
    >
      <gmap-marker
        :position="marker.position"
        :clickable="false"
        :draggable="false"
        @click="center=marker.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>
<script>
export default {
  computed: {
    googleMapUrl() {
      return this.$store.getters.googleMapUrl;
    },
    marker() {
      return this.$store.state.order.address.marker;
    },
    reference() {
      return this.$store.state.order.address.reference;
    },
    center() {
      return { lat: this.marker.position.lat, lng: this.marker.position.lng};
    }
  },
}
</script>
