<template>
  <modal
    :name="name"
    :scrollable="true"
    :max-width="1000"
    :width="width"
    height="auto"
  >
    <div class="info-modal">
      <div class="info-modal__header">
        <h1 class="info-modal__title">{{ title }}</h1>
        <i
          class="material-icons info-modal__close-btn"
          @click="hideModal"
        >
          highlight_off
        </i>
      </div>
      <slot />
      <div class="info-modal__btns">
        <span
          class="btn"
          @click="hideModal"
        >
          Cerrar
        </span>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  props: {
    name: { type: String, required: true },
    title: { type: String, required: true },
    width: { type: String, default: '60%' },
  },
  methods: {
    hideModal() {
      this.$modal.hide(this.name);
    },
  },
};
</script>
