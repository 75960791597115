<template>
  <div>
    <h2 class="order-new__resume-subtitle">Datos de contacto</h2>
    <div class="user-info__table">
      <div class="user-info__table-row">
        <div class="user-info__table-data">Nombre</div>
        <div class="user-info__table-data">Apellido</div>
      </div>
      <div class="user-info__table-row">
        <div class="user-info__table-data">{{ user.name }}</div>
        <div class="user-info__table-data">{{ user.surname }}</div>
      </div>
      <div class="user-info__table-row">
        <div class="user-info__table-data">Correo electrónico</div>
        <div class="user-info__table-data"></div>
      </div>
      <div class="user-info__table-row">
        <div class="user-info__table-data">{{ email }}</div>
        <div class="user-info__table-data"></div>
      </div>
      <div class="user-info__table-row">
        <div class="user-info__table-data">Teléfono</div>
        <div class="user-info__table-data">Teléfono Alternativo</div>
      </div>
      <div class="user-info__table-row">
        <div class="user-info__table-data" >+56 {{ user.numberPrincipal }}</div>
        <div class="user-info__table-data">
          <span v-if="user.numberSecondary != null">+56{{ user.numberSecondary }}</span>
        </div>
      </div>
    </div>

    <h2 class="order-new__resume-subtitle">Información de facturación</h2>
    <div class="user-info__table">
      <div class="user-info__table-row">
        <div class="user-info__table-data">Nombre Empresa</div>
        <div class="user-info__table-data">RUT</div>
      </div>
      <div class="user-info__table-row">
        <div class="user-info__table-data">{{ user.bname }}</div>
        <div class="user-info__table-data">{{ user.rut }}</div>
      </div>
      <div class="user-info__table-row">
        <div class="user-info__table-data">Giro</div>
        <div class="user-info__table-data">Dirección</div>
      </div>
      <div class="user-info__table-row">
        <div class="user-info__table-data">{{ user.bcategory }}</div>
        <div class="user-info__table-data">{{ user.baddress }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['email'],
  computed: {
    user() {
      return this.$store.state.order.user;
    },
  },
};
</script>


