<template>
  <div>
    <div class="select-group">
      <p class="select-group__title">
        Presentación
      </p>
      <ev-select
        v-model="packaging"
        :options="packages"
        :on-change="setPackaging"
      />
    </div>
    <div class="select-group">
      <p class="select-group__title">
&nbsp;
      </p>
      <ev-select
        v-model="form"
        :options="forms"
        :on-change="setForm"
      />
    </div>
  </div>
</template>
<script>
import evSelect from '../ev-select.vue';
import helpers from '../helpers';

export default {
  components: {
    evSelect,
  },
  computed: {
    forms() {
      return this.$store.getters.translatedFormsForFormulatorSelect;
    },
    pelletForms() {
      return this.$store.getters.pelletForms;
    },
    packages() {
      return this.$store.getters.extendedPackagingList;
    },
    form: {
      get() {
        return this.$store.state.recipe.finishing.form;
      },
      set(value) {
        this.$store.commit('updateForm', value);
      },
    },
    packaging: {
      get() {
        return this.$store.state.recipe.finishing.packaging;
      },
      set(value) {
        this.$store.commit('updatePackaging', value);
      },
    },
  },
  methods: {
    translate(word) {
      return helpers.translate(word);
    },
    outdated() {
      this.$store.dispatch('setUpdated', false);
    },
    setForm(value) {
      this.$store.commit('updateForm', value);
      this.outdated();
    },
    setPackaging(option) {
      this.$store.commit('updatePackaging', option);
      const sackWeight = option.weight ? option.weight : null;
      this.$store.commit('updateTotalSackWeight', sackWeight);
      if (option.value === 'bagged25' && !this.pelletForms.includes(this.form.value)) {
        this.$store.commit('updateForm', this.forms.find(e => e.value === 'pellet8'));
      }

      this.outdated();
    },
  },
};
</script>
