<template>
  <modal
    :name="`payment-modal-${orderId}`"
    :scrollable="true"
    :max-width="1000"
    width="25%"
    height="auto"
  >
    <div class="info-modal">
      <h3>Aviso</h3>
      <p>
        A continuación vas a hacer un pago con tarjeta de crédito,
        lo que sumará un 1.5% a tu servicio de venta.
      </p>

      <form
        :action="`/order/${orderId}/payment_retry`"
        method="post"
      >
        <input
          :value="csrfToken"
          type="hidden"
          name="authenticity_token"
        >
        <span>
          <button
            type="submit"
            class="btn"
          >
            Continuar
          </button>
          <button
            type="button"
            class="btn btn-outline"
            @click="$modal.hide(`payment-modal-${orderId}`)"
          >
            Cancelar
          </button>
        </span>
      </form>
    </div>
  </modal>
</template>
<script>
export default {
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    csrfToken() {
      return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    },
  },
};
</script>
