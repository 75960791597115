<template>
  <div
    class="reference-modal"
    style="padding: 20px;">
    <div
      class="reference-modal-bar"
      style="color: #50bfa3; border-bottom: 1px solid #e1e1e1; text-align: right;">
      <i
        class="material-icons reference-modal-close"
        @click="buttonClicked()">highlight_off</i>
    </div>
    <div class="reference-modal-header">
      <h3>Lo sentimos</h3>
    </div>
    <hr >
    <div class="">
      <p> {{ errorMessage }} </p>
      <p>Serás redirigido a la página de inicio para que puedas calcular un nuevo producto</p>
    </div>
    <div class="reference-modal__btns">
      <span
        class="btn"
        @click="buttonClicked()">Volver a Inicio</span>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ErrorModalTemplate',
  data() {
    return {
    };
  },
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
  },
  methods: {
    buttonClicked() {
      this.$emit('button-clicked');
    },
  },
};
</script>
