<script>
  // ExtendedVueSelect.vue
  // https://github.com/sagalbot/vue-select/issues/180
  // https://github.com/sagalbot/vue-select/pull/194
  import vSelect from 'vue-select';

  export default {
    extends: vSelect,
    methods: {
      /**
       * Select a given option.
       * @param  {Object|String} option
       * @return {void}
       */
      select(option) {
        if (this.isOptionSelected(option)) {
          if (this.toggleSelectOption) {
            this.deselect(option)
          }
        } else {
          if (this.taggable && !this.optionExists(option)) {
            option = this.createOption(option)
          }
          if (this.multiple && !this.mutableValue) {
            this.mutableValue = [option]
          } else if (this.multiple) {
            this.mutableValue.push(option)
          } else {
            this.mutableValue = option
          }
        }
        this.onAfterSelect(option)
      },
      /**
       * Toggle the visibility of the dropdown menu.
       * @param  {Event} e
       * @return {void}
       */
      toggleDropdown(e) {
        if (!this.disabled) {
          if (e.target === this.$refs.openIndicator || e.target === this.$refs.search || e.target === this.$refs.toggle || e.target === this.$el) {
            if (this.open) {
              this.$refs.search.blur() // dropdown will close on blur
            } else {
              this.open = true
              this.$refs.search.focus()
            }
          }
        }
      },
    },
    props: {
      /**
       * Enable/Disable deselect the option by double select it
       * @type {Boolean}
       */
      toggleSelectOption: {
        type: Boolean,
        default: false
      },
      /**
       * Enable/Disable
       * @type {Boolean}
       */
      disabled: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped lang="scss">
.v-select::v-deep {
  .selected-tag {
    margin-top: 0;
    font-size: 13px;
    color: #58585b;
  }

  [type=search] {
    width: 1px !important;
    height: 24px !important;
  }

  button {
    display: none;
  }

  .open-indicator {
    width: 40px;
    right: 0;
    text-align: center;
    border-left: 1px solid #c9c9c9;
    height: 100%;
    top: 0;
    padding-top: 7px;
  }

  .open-indicator::before {
    border-color: #418270;
  }

  &.open {
    .dropdown-toggle {
      border-color: #418270;
    }

    .selected-tag {
      color: #418270;
    }

    .open-indicator {
      padding-top: 10px;
      background: #418270;
      border-radius: 0 4px 4px 0;
      border-color: #418270;

      &::before {
        border-color: #fff;
      }
    }
  }

  .dropdown-menu {
    li a {
      font-size: 13px;
      color: #58585b;
    }

    & > .highlight > a,
    li:hover a {
      background: rgba(80, 191, 163, .08);
      color: #58585b;
    }
  }
}
</style>
