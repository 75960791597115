<template>
  <modal
    name="invalid-ingredients-modal"
    :scrollable="true"
    :max-width="1000"
    width="35%"
    height="auto"
    @before-close="beforeClose">
    <error-modal-template
      :error-message="message"
      @button-clicked="$modal.hide('invalid-ingredients-modal');"/>
  </modal>
</template>

<script>
import errorModalTemplate from './error-modal-template.vue';

export default {
  components: { errorModalTemplate },
  name: 'InvalidIngredientsModal',
  data() {
    return {
      message: 'Alguno de los ingredientes que estás cotizando, no cumplen con la inclusión máxima permitida.',
    };
  },
  methods: {
    beforeClose() {
      window.location.href = '/';
    },
  },
};
</script>
