<template>
  <div class="error" :class="{ inline: inline }">
    <span v-if="show">
      {{ errorMessage }}
    </span>
  </div>
</template>
<script>
export default {
  props: ['err', 'attribute', 'display'],
  data() {
    return {
      inline: this.display && this.display === 'inline',
    };
  },
  computed: {
    show() {
      return this.err.hasOwnProperty(this.attribute);
    },
    errorMessage() {
      return this.err[this.attribute][0];
    },
  },
};
</script>
