<template>
  <div id="promotions__content">
    <h2 class="content__title">
      Balanceados
    </h2>
    <div class="cards">
      <carousel-card
        v-for="product in products"
        :key="product.id"
        v-if="product.category === 'promo'"
        :product="product"
      />
    </div>
  </div>
</template>

<script>
import carouselCard from './carousel-card.vue';

export default {
  components: {
    carouselCard,
  },
  computed: {
    products() {
      return this.$store.state.products;
    },
  },
  beforeCreate() {
    this.$parent.getProducts();
  },
};
</script>
