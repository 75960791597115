<template>
  <span class="percentage">
    <vue-numeric :precision="custom" separator="." :read-only="true" :value="value"></vue-numeric> %
  </span>
</template>
<script>
  import VueNumeric from 'vue-numeric';

  export default {
    components: {
      VueNumeric,
    },
    props: ['value', 'precision'],
    computed: {
      custom() {
        return this.precision || 0;
      }
    }
  }
</script>
