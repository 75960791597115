/* global document */

import axios from 'axios';
import qs from 'qs';
import { camelizeKeys, decamelizeKeys } from 'humps';
import vars from '../vars';

export default {
  requestCncpsExecution(params) {
    return axios.post('/api/v1/cncps_formulator_executions.json', decamelizeKeys(params), {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
    }).then((response) => [true, camelizeKeys(response.data)])
      .catch((error) => [false, camelizeKeys(error.response.data)]);
  },
  getCncpsExecution(identifier) {
    return axios.get(`/api/v1/cncps_formulator_executions/${identifier}.json`)
      .then((response) => camelizeKeys(response.data));
  },
  cheapestRecipe(params) {
    return axios.get('/api/v1/recipes/cheapest.json', {
      params: decamelizeKeys(params),
    }).then((response) => response.data.details);
  },
  // eslint-disable-next-line max-params
  inverseRecipe(ingredients, form, packaging, totalSackWeight, nutritionalInfo = null) {
    const params = { ingredients, packaging, form };
    if (totalSackWeight) params.totalSackWeight = totalSackWeight;
    if (nutritionalInfo) params.nutritionalInfo = nutritionalInfo;

    const paramsString = qs.stringify(decamelizeKeys(params), { encode: false, arrayFormat: 'brackets' });

    return axios.get(`/api/v1/recipes/inverse.json?${paramsString}`).then((response) => response.data.details);
  },
  searchAddress(pos) {
    return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&key=${vars.googleMapsKey}`)
      .then((response) => response.data.results[1].formatted_address);
  },
  createOrder(params) {
    return axios.post('/api/v1/orders/', {
      body: params,
    }, {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
    }).then((response) => camelizeKeys(response.data));
  },
  createDeliveryAddress(params) {
    return axios.post('/api/v1/delivery_addresses/', {
      body: params,
    }, {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
    }).then((response) => response);
  },
  editDeliveryAddress(params) {
    return axios.put('/api/v1/profile/delivery_address', {
      delivery: params,
    }, {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
    }).then((response) => response);
  },
  deleteDeliveryAddress(params) {
    return axios.delete('/api/v1/profile/delivery_address', {
      data: {
        delivery: params,
      },
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
    }).then((response) => response);
  },
  getOrderFreightValue(params) {
    return axios.get('/api/v1/freight_price', { params: decamelizeKeys(params) })
      .then((response) => response.data);
  },
  getProductDetails(id) {
    return axios.get(`/api/v1/products/${id}.json`)
      .then((response) => response.data);
  },
  createBillingAddress(params) {
    return axios.post('/api/v1/billing_addresses/', {
      body: params,
    }, {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
    }).then((response) => response);
  },
};
